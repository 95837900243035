import { Injectable } from '@angular/core';

import { Subscription, merge, of } from 'rxjs';

import { AuthService } from './auth.service';
import { LinkService, NetworkErrorCode } from './link.service';
import { SentryService } from './sentry.service';
import { debounce, debounceTime, filter, first, map, tap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { UIService } from './ui.service';
import { LoggingService } from './logging.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private authService: AuthService,
    private linkService: LinkService,
    private uiService: UIService,
    private router: Router,
    private log: LoggingService,
    private sentryService: SentryService
  ) {}

  public static isUnauthenticatedUrl = (url: string) =>
    url.startsWith('/account-action') ||
    url.startsWith('/change-password') ||
    url.startsWith('/reset-password') ||
    url.startsWith('/login');

  monitorExpiries() {
    let checkPassword$: Subscription;
    let checkAuthorization$: Subscription;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (checkPassword$) {
        checkPassword$.unsubscribe();
      }
      if (checkAuthorization$) {
        checkAuthorization$.unsubscribe();
      }
      this.log.info(event.url);
      if (!UserService.isUnauthenticatedUrl(event.url)) {
        checkPassword$ = this.passwordExpiredObs(event.url).subscribe();
        checkAuthorization$ = this.authorizationExpiredObs(event.url).subscribe();
      }
    });
    checkPassword$ = this.passwordExpiredObs().subscribe();
    checkAuthorization$ = this.authorizationExpiredObs().subscribe();
  }

  monitorClaimsDeprecated() {
    // check the current URL and then respond depending on the url which is being navigated to
    let checkClaims: Subscription;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (checkClaims) {
        checkClaims.unsubscribe();
      }
      this.log.info(event.url);
      if (!UserService.isUnauthenticatedUrl(event.url)) {
        checkClaims = this.claimsDeprecatedObs(event.url).subscribe();
      }
    });
    checkClaims = this.claimsDeprecatedObs().subscribe();
  }

  private passwordExpiredObs = (url?: string) => {
    if (!environment.internalPasswordExpiryCheck) {
      return of(undefined);
    }
    return this.linkService.networkErrors().pipe(
      filter((data) => data && data.code && data.code === NetworkErrorCode.PASSWORD_EXPIRED),
      tap(() => this.log.debug('networkErrors expired')),
      filter(() => !UserService.isUnauthenticatedUrl(url || this.router.url)),
      debounceTime(100),
      first(),
      tap(() => this.showPasswordExpiredModal())
    );
  };

  private authorizationExpiredObs = (url?: string) => {
    if (!environment.internalAuthorizationExpiryCheck) {
      return of(undefined);
    }
    return this.authService.hasAuthorizationExpired().pipe(
      filter((result) => result && result !== 'sign-out' && !UserService.isUnauthenticatedUrl(url || this.router.url)),
      debounceTime(100),
      first(),
      tap((result) => {
        // due to the debounce we could now be at an unauthenticated url so double check
        if (!UserService.isUnauthenticatedUrl(url || this.router.url)) {
          this.showAuthorizationExpiredModal(result === 'no-expiry');
        }
      })
    );
  };

  private claimsDeprecatedObs = (url?: string) =>
    this.linkService.networkErrors().pipe(
      filter((data) => data && data.code && data.code === NetworkErrorCode.DEPRECATED_CLAIMS),
      tap((data) => this.log.debug(`networkErrors code=${data.code} message=${data.message}`)),
      filter(() => !UserService.isUnauthenticatedUrl(url || this.router.url)),
      first(),
      tap(() => this.showLoginRequiredModal())
    );

  resetPassword(email, newPassword) {
    return this.linkService
      .GQLCentral(false)
      .mutate<{ setPassword: { success: boolean; schedulerOnly?: boolean } }>({
        mutation: gql`
          mutation resetPassword($newPassword: String!, $email: String!) {
            setPassword(newPassword: $newPassword, email: $email) {
              schedulerOnly
              success
            }
          }
        `,
        variables: { email, newPassword },
      })
      .pipe(map(({ data }) => !!data.setPassword?.success));
  }

  updateSSOID(ssoId) {
    return this.linkService
      .GQLCentral(false)
      .mutate<{ updateAccountSSOID: string }>({
        mutation: gql`
          mutation updateAccountSSOID($ssoId: String!) {
            updateAccountSSOID(ssoId: $ssoId)
          }
        `,
        variables: { ssoId },
      })
      .pipe(map(({ data }) => !!data.updateAccountSSOID));
  }

  requestPasswordReset(email) {
    return this.linkService
      .GQLUnauth()
      .mutate({
        mutation: gql`
          mutation requestPasswordReset($email: String!) {
            requestPasswordReset(email: $email, scheduler: true)
          }
        `,
        variables: { email },
      })
      .pipe(map(({ data }: any) => !!data.requestPasswordReset));
  }

  changePassword(email, oldPassword, newPassword) {
    return this.linkService
      .GQLCentral(false)
      .mutate<{ changePassword?: { success; schedulerOnly } }>({
        mutation: gql`
          mutation changePassword($newPassword: String!, $email: String!, $oldPassword: String!) {
            changePassword(email: $email, newPassword: $newPassword, oldPassword: $oldPassword) {
              success
              schedulerOnly
            }
          }
        `,
        variables: { email, newPassword, oldPassword },
      })
      .pipe(map(({ data }) => !!data.changePassword?.success));
  }

  showPasswordExpiredModal() {
    const summary = 'Password expired';
    const detail = 'Your password has expired and needs to be changed';
    this.uiService.confirmActionDanger(
      detail,
      'Update',
      () => {
        this.authService
          .currentUser()
          .pipe(first())
          .subscribe((user) => {
            this.router.navigate([`/change-password${user ? '/' + user.email : ''}`]);
          });
      },
      () => this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } }),
      summary,
      'Logout'
    );
  }

  showAuthorizationExpiredModal(missingExpiry: boolean) {
    this.uiService.confirmAction(
      'Your login has expired. Please log in again.',
      () => {
        this.authService
          .signOut()
          .pipe(first())
          .subscribe(() => {
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
          });
      },
      () => {
        this.authService
          .signOut()
          .pipe(first())
          .subscribe(() => {
            this.router.navigate(['/login/manual'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
          });
      },
      'Login again',
      'Logout'
    );
  }

  showLoginRequiredModal() {
    this.uiService.acknowledgeAction(
      'You need to log in again in order to update your access.',
      () => this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } }),
      'New login required'
    );
  }
}
