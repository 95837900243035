import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ITableDefinition } from '../../components/display-table/display-table.component';
import { ContextService } from 'src/services/context.service';
import { UIService } from 'src/services/ui.service';
import { first } from 'rxjs/operators';
import { LoggingService } from 'src/services/logging.service';
import { DeviceDataService } from 'src/services/device-data.service';
import { environment } from './../../environments/environment';

const defaultTableCols = [
  {
    label: 'Name',
    value: 'name',
    sort: true,
    filter: 'none',
  },
  {
    label: 'Description',
    value: 'description',
    sort: true,
    filter: 'none',
  },
  {
    label: 'Disabled',
    value: 'disabled',
    filter: 'none',
  },
  {
    label: 'Last Usage',
    value: 'lastcheckin',
    sort: true,
    filter: 'none',
  },
];

@Component({
  selector: 'app-devices',
  styleUrls: ['./devices.component.scss'],
  templateUrl: './devices.component.html',
})
export class DevicesComponent implements OnInit, OnDestroy {
  loading = false;
  hotelWatch$: Subscription;
  editDevice: any = null;
  devices: any = null;
  savingEdit = false;

  tblDef: ITableDefinition = {
    showAdd: true,
    addLabel: 'Add Device',
    colFilters: true,
    cols: defaultTableCols,
  };

  constructor(
    private contextService: ContextService,
    private deviceData: DeviceDataService,
    private uiService: UIService,
    private log: LoggingService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.hotelWatch$ = this.contextService.getCurrentBasicHotel$().subscribe((hotel) => {
      if (!hotel || !hotel.id) return;
      this.editDevice = null;
      this.devices = null;
      this.loading = false;
      this.loadDevices();
    });
  }

  ngOnDestroy(): void {
    if (this.hotelWatch$) {
      this.hotelWatch$.unsubscribe();
    }
  }

  loadDevices() {
    this.loading = true;
    this.log.debug('loading devices');
    this.deviceData
      .getDevices()
      .pipe(first())
      .subscribe(
        (data) => {
          this.log.debug('retrieved devices');
          this.devices = [...data];
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  addRow() {
    this.editDevice = {
      id: null,
      name: '',
      description: '',
    };
  }

  editRow(device: any) {
    this.editDevice = { ...device };
  }

  removeDevice() {
    this.uiService.confirmActionDanger('Remove this device, the device will no longer be available for use.', 'Remove', () => {
      this.deviceData.removeDevice(this.editDevice.id).subscribe((d) => {
        this.devices = this.devices.filter((d) => d.id != this.editDevice.id);
        this.editDevice = null;
      });
    });
  }

  async saveDevice(doLink: boolean) {
    this.savingEdit = true;
    if (!this.editDevice.id) {
      this.deviceData.createDevice(this.editDevice).subscribe((d) => {
        this.devices.push(d);
        this.editDevice = d;
        if (doLink) this.createDeviceLink();
        else this.editDevice = null;
        this.savingEdit = false;
      });
    } else {
      this.deviceData.updateDevice(this.editDevice).subscribe((newD) => {
        this.devices = this.devices.map((d) => (d.id == this.editDevice.id ? newD : d));
        this.editDevice = null;
        this.savingEdit = false;
      });
    }
  }

  async createDeviceLink() {
    this.deviceData.generateToken(this.editDevice).subscribe((t) => {
      let link = environment.checkInUrl + '/activate/' + t.accessToken;
      navigator.clipboard.writeText(link);
      this.uiService.acknowledgeAction(
        `Activation link has been copied to your clipboard. Please note that this is a unique link and will only be available once.`,
        undefined,
        'Activation link for Check In Device'
      );
      this.editDevice = null;
    });
  }
}
