<div class="p-grid p-justify-between p-align-center topbar" id="top">
  <div class="p-col-fixed">
    <a *ngIf="$authRoute | async" class="navbar-brand" (click)="displaySidebar = true"><i class="pi pi-bars"></i></a>
  </div>
  <figure>
    <img src="assets/ihgtheme/imgs/IHG_white_Logo.png" />
  </figure>
  <span class="modulename"
    >{{ schedulerName }}<span class="env">{{ env }}</span></span
  >
  <div class="ui-widget p-col"></div>
  <h1 class="ui-widget p-col">{{ getRouteTitle() }}</h1>
  <ng-container *ngIf="$authRoute | async">
    <div class="p-col-fixed">
      <button class="navbar-brand mr-auto regcode" pButton [label]="contextService.describeCurrentTZ()"></button>
      <button
        class="navbar-brand mr-auto regcode"
        pButton
        (click)="hotelmenu.toggle($event)"
        [label]="contextService.$currentHotelName | async"
        *ngIf="contextService.$hotelMenu | async"
      ></button>
      <p-menu #hotelmenu appendTo="body" [popup]="true" [model]="contextService.$hotelMenu | async"></p-menu>
      <button
        class="navbar-brand mr-auto regcode"
        pButton
        *ngIf="contextService.$regionMenu | async"
        (click)="regmenu.toggle($event)"
        [label]="contextService.$currentRegion | async"
      ></button>
      <p-menu #regmenu appendTo="body" [popup]="true" [model]="contextService.$regionMenu | async"></p-menu>
    </div>
    <a class="navbar-brand mr-auto" (click)="rmenu.toggle($event)"><i class="pi pi-ellipsis-v"></i></a>
    <p-menu #rmenu appendTo="body" popup="true" [model]="contextService.getRightMenu() | async"></p-menu>
  </ng-container>
</div>
<p-sidebar *ngIf="$authRoute | async" [(visible)]="displaySidebar" position="left" [style]="{ overflow: 'auto' }">
  <p-panelMenu [model]="contextService.getMainMenu() | async" [style]="{ width: '100%' }"></p-panelMenu>
</p-sidebar>
<div class="topbar-spacer"></div>
<p-toast position="top-center"></p-toast>
<p-toast key="bottom-right" position="bottom-right"></p-toast>
<p-toast key="network" position="bottom-right"></p-toast>
<app-confirmation-dialog></app-confirmation-dialog>
<div class="mainwindow">
  <div *ngIf="!available && !closed">
    <p-messages severity="warn">
      <ng-template pTemplate>
        <div>{{ closedMessage || 'This service is currently unavailable.' }}</div>
      </ng-template>
    </p-messages>
  </div>
  <ng-container *ngIf="!closed; else closedService">
    <router-outlet></router-outlet>
  </ng-container>
</div>
<ng-template #closedService>
  <div style="margin: 2em 6em">
    <p-messages severity="warn">
      <ng-template pTemplate>
        <div>{{ closedMessage || 'This service is currently unavailable.' }}</div>
      </ng-template>
    </p-messages>
  </div>
</ng-template>
<p-dialog header="Capture SSO ID" [(visible)]="captureSSO" [closable]="false" responsive showEffect="fade" modal="true">
  <div class="content">
    <div class="p-grid fieldbox">
      <div class="p-col-12">Your account does not have an associated Merlin ID. Please add in your Merlin ID to continue.</div>
      <label class="p-col-5">Merlin ID:</label>
      <div class="p-col-6"><input type="text" pInputText [(ngModel)]="ssoId" [disabled]="savingSSO" required /></div>
      <label class="p-col-5">Confirm Merlin ID: </label>
      <div class="p-col-6"><input type="text" pInputText [(ngModel)]="confirmSSOId" [disabled]="savingSSO" required /></div>
    </div>
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      [icon]="savingSSO ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      (click)="saveSSO()"
      label="Save"
      [disabled]="!ssoId?.length || ssoId !== confirmSSOId || savingSSO"
      class="p-button-danger"
    ></button>
  </p-footer>
</p-dialog>
