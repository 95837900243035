<p-tieredMenu #menu [popup]="true" [model]="addOptions" appendTo="body" [styleClass]="dropRight ? 'dropRight' : 'dropLeft'"></p-tieredMenu>
<div class="container">
  <p-table [value]="displayRows" [loading]="isLoading" #dt styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="flex">
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-ai-center">
        <label
          >Date
          <p-button icon="pi pi-arrow-left" (click)="setCal(-1)" class="p-mx-1"></p-button>
          <p-calendar
            [(ngModel)]="selectedTime"
            #calendar
            (ngModelChange)="dateChange()"
            dateFormat="yy-mm-dd"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            [showIcon]="true"
            appendTo="body"
          >
            <ng-template pTemplate="footer">
              <div class="calendar-footer">
                <button pButton pRipple type="button" label="Now" class="p-button-text" (click)="setToday(); calendar.toggle()"></button>
              </div>
            </ng-template>
          </p-calendar>
          <p-button icon="pi pi-arrow-right" (click)="setCal(+1)" class="p-mx-1"></p-button>
        </label>
        <label
          >Display
          <p-dropdown
            [options]="showTimes"
            [(ngModel)]="showTime"
            optionLabel="label"
            optionValue="code"
            appendTo="body"
            (ngModelChange)="dateChange($event)"
          >
          </p-dropdown>
        </label>
        <p-checkbox
          *ngIf="allowBlockChange"
          [(ngModel)]="showBlocks"
          [binary]="true"
          (ngModelChange)="calculateAll()"
          label="Use Block Display"
          persistLocal="scheduleuseblocks"
        ></p-checkbox>
        <div class="p-d-inline-flex p-flex-column">
          <p-checkbox
            *ngIf="allowMultDepartments"
            [(ngModel)]="useMultDeps"
            [binary]="true"
            label="Multiple Departments"
            (onChange)="multiDepChange()"
          ></p-checkbox>

          <label
            >Department
            <app-full-department-drop-down
              *ngIf="!useMultDeps"
              [(ngModel)]="showDepartment"
              (onChange)="depChange()"
              [verifyDepartmentAccess]="true"
              persistLocal="scheduledepartment"
              [multi]="false"
            >
            </app-full-department-drop-down>
            <app-full-department-drop-down
              *ngIf="useMultDeps"
              [(ngModel)]="showDepartmentsList"
              (onChange)="depChange()"
              [verifyDepartmentAccess]="true"
              [multi]="true"
            >
            </app-full-department-drop-down>
          </label>
        </div>
        <p-button
          *ngIf="extraCommand"
          icon="pi {{ extraCommandIcon }}"
          class="p-mx-1 p-button-sm"
          [label]="extraCommand"
          (click)="doExtraCommand()"
          [disabled]="useMultDeps"
          [pTooltip]="useMultDeps ? 'Not available for multi-department view' : ''"
        ></p-button>
        <p-button
          *ngIf="extraCommand2"
          icon="pi {{ extraCommandIcon2 }}"
          class="p-mx-1 p-button-sm"
          [label]="extraCommand2"
          (click)="doExtraCommand2()"
          [disabled]="useMultDeps"
          [pTooltip]="useMultDeps ? 'Not available for multi-department view' : ''"
        ></p-button>
        <p-button
          icon="pi pi-download"
          class="p-mx-1 p-button-sm"
          label="Export"
          (click)="export(menu, $event)"
          [disabled]="useMultDeps"
          [pTooltip]="useMultDeps ? 'Not available for multi-department view' : ''"
        ></p-button>
        <p-button icon="pi pi-undo" class="p-mx-1 p-button-sm" label="Back" (click)="goBack()" *ngIf="backRoute"></p-button>
        <help-overlay *ngIf="helpfiles" [helpfiles]="helpfiles"></help-overlay>
      </div>
      <div *ngIf="updated" style="margin-top: -8.5px; margin-bottom: -6px">
        <span style="font-size: x-small; font-weight: lighter"
          >Retrieved:<ng-container *ngIf="updated"> {{ updated | date: 'YYYY-MM-dd HH:mm (z)' }}</ng-container></span
        >
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr class="headerlevel">
        <th style="width: 2rem" *ngIf="showPersonChecks">
          <p-checkbox [(ngModel)]="checkAll" (onChange)="setChecks($event)" [binary]="true"></p-checkbox>
          <div class="checkedtext">{{ checkedCount | number: '1.0-0' }} / {{ displayRows.length - 1 | number: '1.0-0' }}</div>
        </th>
        <th style="width: 20em">
          <span style="padding-right: 0.5em">Employee</span>
          <i pTooltip="Select Sort" tooltipPosition="bottom" class="pi pi-sort" (click)="sortmenu.toggle($event)"></i>
          <p-menu #sortmenu [popup]="true" [model]="sortMenu" appendTo="body"></p-menu>

          <label *ngIf="canViewHolidays" style="padding-left: 1.2em" class="holiday" pTooltip="Show Leave Taken" tooltipPosition="bottom"
            >Leave<p-checkbox
              style="padding-left: 0.5em"
              class="holiday"
              [(ngModel)]="viewHolidays"
              [binary]="true"
              persistLocal="shiftViewHolidays"
            ></p-checkbox>
          </label>
        </th>
        <th *ngFor="let d of displayDateList; let i = index">
          <ng-container *ngIf="showTime != 0">
            <div>{{ d | dayjs: 'DD MMM' }}</div>
            <div>{{ d | dayjs: 'ddd' }}</div>
          </ng-container>
          <ng-container *ngIf="showTime == 0">
            <div>{{ d | dayjs: 'DD MMM' }}</div>
            <div>{{ d | dayjs: 'HH:mm' }}</div>
          </ng-container>
          <div>
            <span class="bgcoltext" *ngIf="dateScheduleStatus">
              <ng-container *ngIf="showTime !== 0 || i == displayDateList.length - 1">
                <i *ngIf="!showColAddOnSelection || checkedCount" class="pi pi-pencil" (click)="addCol(menu, $event, i)"></i>
                <ng-container *ngIf="!useMultDeps">
                  <i class="pi pi-spin pi-spinner" *ngIf="isScheduleLoading"></i>
                  <ng-container *ngIf="!isScheduleLoading">
                    <span
                      *ngIf="loadCapturedShift && dateShiftsConfirmed"
                      [ngClass]="{ warning: !!(dateShiftsCaptured[i] < dateShiftsCount[i]) }"
                      pTooltip="{{ dateShiftsCaptured[i] }}  of {{ dateShiftsCount[i] }} shifts submitted {{ '\n' }} {{
                        dateShiftsConfirmed[i]
                      }} shifts locked."
                    >
                      {{ dateShiftsCaptured[i] }} / {{ dateShiftsCount[i] }}
                    </span>
                    &nbsp;
                    <i
                      *ngIf="showColSave"
                      [pTooltip]="saveToolTip(i)"
                      tooltipPosition="bottom"
                      class="pi {{ saveIcon }} savebutton"
                      (click)="saveCol(menu, $event, i)"
                      [ngClass]="[dateScheduleStatus[i].status || 'UNKNOWN', dateScheduleStatus[i].missedSubmit ? 'MISSED' : 'NOMISS']"
                    ></i>
                    &nbsp;
                    <i
                      *ngIf="showColApprove && dateScheduleStatus[i].status == 'SUBMITTED'"
                      [pTooltip]="submittedToolTip[i]"
                      tooltipPosition="bottom"
                      class="pi {{ approveIcon }} approvebutton"
                      (click)="approveCol(menu, $event, i)"
                      [ngClass]="[dateScheduleStatus[i].status || 'UNKNOWN', dateScheduleStatus[i].missedApprove ? 'MISSED' : 'NOMISS']"
                    ></i>
                    <i
                      *ngIf="showColApprove && ['APPROVED', 'AUTO_APPROVED'].includes(dateScheduleStatus[i].status)"
                      [pTooltip]="approveToolTip(i)"
                      tooltipPosition="bottom"
                      class="pi approvebutton"
                      (click)="unapproveCol(menu, $event, i)"
                      [ngClass]="[
                        dateScheduleStatus[i].status || 'UNKNOWN',
                        dateScheduleStatus[i].missedApprove ? 'MISSED' : 'NOMISS',
                        dateScheduleStatus[i].missedApprove ? missedUnapproveIcon : unapproveIcon
                      ]"
                    ></i>
                  </ng-container>
                </ng-container>
              </ng-container>
            </span>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr *ngIf="rowData.drawHeader" class="sectionheader">
        <td style="width: 2rem" *ngIf="showPersonChecks"></td>
        <td style="width: 20em">
          <label>{{ pTypes[rowData.pType].label }}</label>
          <button
            pButton
            *ngIf="!pTypes[rowData.pType].display"
            class="p-button-sm p-button-text"
            type="button"
            label="Show {{ pTypes[rowData.pType].count }} hidden rows"
            (click)="pTypes[rowData.pType].display = true"
          ></button>
          <button
            pButton
            *ngIf="pTypes[rowData.pType].display"
            class="p-button-sm p-button-text p-button-secondary"
            type="button"
            label="Hide {{ pTypes[rowData.pType].count }} row(s)"
            (click)="pTypes[rowData.pType].display = false"
          ></button>
        </td>
        <td *ngFor="let d of displayDateList; let i = index" class="datecell"></td>
      </tr>
      <tr *ngIf="pTypes[rowData.pType].display" [ngClass]="rowData.rowChecked ? 'selected' : 'std'">
        <td style="width: 2rem" *ngIf="showPersonChecks">
          <p-checkbox
            *ngIf="!rowData.isAgency"
            [(ngModel)]="rowData.rowChecked"
            (ngModelChange)="rowCheckChanged(rowData)"
            [binary]="true"
          ></p-checkbox>
        </td>
        <td
          [ngStyle]="{ 'height.rem': showBlocks ? rowData.rowHeight * 2 + 1 : rowData.rowHeight * 4 }"
          *ngIf="!rowData.isAgency"
          style="width: 20em"
        >
          <div class="shift-name">
            <a
              *ngIf="isPersonAdmin && !rowData.isOtherHotel"
              tooltipPosition="bottom"
              class="llink"
              [pTooltip]="rowData.tooltip ? 'View employee details\n' + rowData.tooltip : 'View employee details'"
              (click)="openPerson(rowData.id)"
              >{{ rowData.firstName }} {{ rowData.lastName }}</a
            >
            <span *ngIf="!isPersonAdmin || rowData.isOtherHotel" [pTooltip]="rowData.tooltip" tooltipPosition="bottom"
              >{{ rowData.firstName }} {{ rowData.lastName }}</span
            >
            <span class="shift-time" [ngClass]="rowData.totalTime > rowData.contractTime ? 'OVER' : 'STD'"
              >({{ rowData.totalTime | number: '1.0-1' }} / {{ rowData.contractTime | number: '1.0-1' }}) </span
            ><span class="commentmark" *ngIf="rowData.onlyStartsAt">*</span><span class="commentmark" *ngIf="rowData.endsAt">*</span>
          </div>
          <div class="shift-contract">
            {{ rowData.currentContract.title }} <span class="other-hotel" *ngIf="rowData.isOtherHotel">({{ rowData.holidexCode }})</span>
          </div>
          <div
            *ngIf="canViewHolidays && viewHolidays"
            class="shift-holiday"
            [ngClass]="rowData.holidayTaken > rowData.holidayEntitlement ? 'OVER' : rowData.holidayProRate ? 'PRORATA' : 'STD'"
            [pTooltip]="rowData.holidayTip"
            tooltipPosition="bottom"
          >
            ({{ rowData.holidayProRate ? 'PRO RATA ' : '' }}{{ rowData.holidayTaken | number: '1.0-1' }} /
            {{ rowData.holidayEntitlement | number: '1.0-1' }} leave {{ holidayType }})
          </div>
        </td>
        <td [ngStyle]="{ 'height.rem': rowData.rowHeight * 4 }" *ngIf="rowData.isAgency">
          <div class="shift-agency">
            AGENCY
            <span class="shift-time" [ngClass]="'STD'">({{ rowData.totalTime | number: '1.0-1' }}) </span>
          </div>
          <div class="shift-contract">(Non hotel-contracted staff)</div>
        </td>
        <td
          *ngFor="let d of displayDateList; index as i"
          class="datecell"
          pDroppable="shiftdrag"
          (onDrop)="dropShift(rowData, i, $event)"
          [ngClass]="+d < dateToday ? 'past' : 'std'"
        >
          <div>
            <ng-container
              *ngIf="
                (rowData.onlyStartsAt ? +d >= +rowData.onlyStartsAt : true) &&
                  (rowData.endsAt ? +d < +rowData.endsAt + 24 * 60 * 60 * 1000 : true);
                else unavailable
              "
            >
              <span class="bgtext" [ngClass]="showBlocks ? 'blocks' : 'std'" (click)="addCell(menu, $event, rowData, i)"
                ><i class="pi pi-plus"></i
              ></span>
            </ng-container>
          </div>
          <ng-container *ngFor="let shift of rowData.shifts">
            <ng-container
              *ngIf="
                +d == +shift.shiftDay ||
                (showTime != 0 && i == 0 && +d === +shift.shiftEndDay) ||
                (showTime === 0 && +d === +shift.shiftEndDay)
              "
            >
              <app-shift-marker
                *ngIf="!(rowData.isAgency && shift.otherDep)"
                [shift]="shift"
                [timeStart]="+timeStart"
                [timeEnd]="+timeEnd"
                [cellStart]="+d"
                [timeWidth]="displayCellLength"
                [showFullDetail]="showTime < 3"
                (sclick)="shiftClick(menu, $event, i, rowData, shift)"
                [showAsBlock]="showBlocks"
                [showCaptured]="loadCapturedShift"
                [dateScheduleStatus]="dateScheduleStatus[i]"
                [showDepartment]="useMultDeps"
                pDraggable="shiftdrag"
                dragEffect="all"
                (onDragStart)="dragShift(rowData, shift, $event)"
              >
              </app-shift-marker>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let checkIn of rowData.checkIns; index as i">
            <ng-container *ngIf="+d == +checkIn.checkInDay">
              <app-checkin-marker
                [checkIn]="checkIn"
                [timeStart]="+timeStart"
                [timeEnd]="+timeEnd"
                [cellStart]="+d"
                [timeWidth]="displayCellLength"
                [displayIndex]="i"
                [blockMode]="showBlocks"
              >
              </app-checkin-marker>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="showDepartment && showTime == 0" class="breaker">
        <td style="width: 2rem" *ngIf="showPersonChecks"></td>
        <td style="width: 20em">Number of employees by hour</td>
        <td *ngFor="let v of cellShiftsCount" class="totalcell">
          {{ v | number: '1.0-0' }}
        </td>
      </tr>
      <ng-container *ngIf="context.allowOptimals() | async">
        <tr *ngIf="showDepartment">
          <td style="width: 2rem" *ngIf="showPersonChecks"></td>
          <td style="width: 20em">
            <span tooltipPosition="bottom" [pTooltip]="optimalTotal + ' hours total'"
              >{{ loadCapturedShift ? 'Actual' : 'Forecast' }} optimal hours</span
            >
          </td>
          <td *ngFor="let v of dateOptimals" class="totalcell" [attr.colspan]="displayCells / daysShown">
            {{ v | number: '1.0-1' }}
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="showDepartment">
        <td style="width: 2rem" *ngIf="showPersonChecks"></td>
        <td style="width: 20em">
          <span tooltipPosition="bottom" [pTooltip]="dateTotal + ' hours total'"
            >{{ loadCapturedShift ? 'Actual' : 'Scheduled' }} working hours</span
          >
        </td>
        <td
          *ngFor="let v of dateTotals; let i = index"
          class="totalcell"
          [attr.colspan]="displayCells / daysShown"
          [ngClass]="dateOptimals && v > dateOptimals[i] ? 'OVEROPTIMAL' : 'STD'"
        >
          {{ v | number: '1.0-1' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="!showDepartment && !isLoading" class="warning">Please select a department to start.</div>
</div>
<ng-template #unavailable>
  <span class="bgtext-unavailable" [ngClass]="showBlocks ? 'blocks' : 'std'"><i class="pi pi-times"></i></span>
</ng-template>
