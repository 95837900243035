import { Component, OnInit } from '@angular/core';
import { cloneShift, Right, runAndRetry, Scope } from 'src/commontypes/util';

import { ScheduleDataService } from 'src/services/schedule-data.service';
import * as dayjs from 'dayjs';
import { LocalService } from 'src/services/local.service';
import { UIService } from 'src/services/ui.service';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { SentryService } from 'src/services/sentry.service';
import { AuthService } from 'src/services/auth.service';
import { Subscription } from 'rxjs';
import { XLSService } from 'src/services/xls.service';
import { getAbsenceTypeLookUp } from 'src/commontypes/shifts';
import { LoggingService } from 'src/services/logging.service';
import { ApolloError } from '@apollo/client/core';
import { ContextService } from 'src/services/context.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-schedule-shifts',
  styleUrls: ['./scheduleshifts.component.scss'],
  templateUrl: './scheduleshifts.component.html',
})
export class ScheduleShiftsComponent implements OnInit {
  constructor(
    public scheduleData: ScheduleDataService,
    private local: LocalService,
    private uiService: UIService,
    private context: ContextService,
    private sentryService: SentryService,
    private authService: AuthService,
    private xls: XLSService,
    private log: LoggingService,
    private messageService: MessageService
  ) {}

  editShift = null;
  canWrite = false;
  canApprove = false;
  role$: Subscription;
  arole$: Subscription;

  public aTypes = getAbsenceTypeLookUp();

  public cloneEv: any = null;

  ngOnInit() {
    this.role$ = this.authService
      .hasAnyRoles([
        [Scope.SCHEDULE, Right.WRITE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.REGION_ADMIN, Right.WRITE],
      ])
      .pipe(distinctUntilChanged())
      .subscribe((v) => (this.canWrite = v));
    this.arole$ = this.authService
      .hasAnyRoles([
        [Scope.SCHEDULE, Right.APPROVE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.REGION_ADMIN, Right.WRITE],
      ])
      .subscribe((v) => (this.canApprove = v));
  }

  ngOnDestroy() {
    if (this.role$) {
      this.role$.unsubscribe();
      this.role$ = undefined;
    }
    if (this.arole$) {
      this.arole$.unsubscribe();
      this.arole$ = undefined;
    }
  }

  addShift(rows, start, dep, refresh, deps, shiftSetup = null) {
    const {
      currentContract: { contractedHours, workDays },
    } = rows[0] || { currentContract: { contractedHours: 40, workDays: 5 } };
    const defaultBreak = 30;
    let sampleRow = rows[0];
    let quickSave = false;

    if (!shiftSetup)
      shiftSetup = {
        type: 'WORK',
        start: this.context.contextDateToISO(dayjs.utc(start).hour(8).minute(0)),
        end: this.context.contextDateToISO(
          dayjs
            .utc(start)
            .hour(8)
            .add(contractedHours / (workDays || 1) || 8, 'hour')
            .add(defaultBreak, 'minute')
        ),
        breaks: defaultBreak,
        comments: '',
        quantity: 1,
      };
    else {
      //just modify the times
      quickSave = true;
      let s = this.context.isoToContextDate(shiftSetup.start);
      let e = this.context.isoToContextDate(shiftSetup.end);
      let dayOffset = s.date() != e.date() ? 1 : 0;
      shiftSetup.start = this.context.contextDateToISO(dayjs.utc(start).hour(s.hour()).minute(s.minute()));
      shiftSetup.end = this.context.contextDateToISO(dayjs.utc(start).add(dayOffset, 'day').hour(e.hour()).minute(e.minute()));
      shiftSetup.quantity = 1;
      if (!sampleRow.isAgency && shiftSetup.type == 'AGENCY') shiftSetup.type = 'WORK';
    }

    if (sampleRow.isAgency || sampleRow.agency) shiftSetup.type = 'AGENCY'; //always force agency type for these

    if (!deps) {
      shiftSetup.departmentName = dep.name;
      shiftSetup.outletIndex = dep.outletIndex;
      shiftSetup.outletType = dep.outletType;
      shiftSetup.subDeptName = dep.subDeptName;
    }

    let persons = rows.reduce((a, v) => {
      return a + v.firstName + ' ' + v.lastName + '; ';
    }, '');

    this.editShift = {
      rows,
      ids: new Array(rows.length).fill(0),
      personLabel: persons,
      personCount: rows.length,
      departmentLabel: deps ? shiftSetup.departmentLabel : dep.label,
      departmentName: deps ? shiftSetup.departmentName : dep.name,
      subDeptName: deps ? shiftSetup.subDeptName : dep.subDeptName,
      outletType: deps ? shiftSetup.outletType : dep.outletType,
      outletIndex: deps ? shiftSetup.outletIndex : dep.outletIndex,
      departments: deps,
      detail: shiftSetup,
      refresh,
    };
    if (quickSave) this.saveEditShift(true);
  }

  modifyShift(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot modify shifts', 'You do not have permission to modify shifts on this system.');
      return;
    }

    if (ev.shift.otherDep) this.uiService.acknowledgeError('This shift is not for the selected department and cannot be modified.');
    else
      this.editShift = {
        rows: [ev.person],
        ids: [ev.shift.id],
        personCount: 1,
        personLabel: ev.person.firstName + ' ' + ev.person.lastName,
        departmentLabel: ev.shift.departmentLabel,
        departmentName: ev.shift.departmentName,
        subDeptName: ev.shift.subDeptName,
        departments: ev.departments,
        outletType: ev.shift.outletType,
        outletIndex: ev.shift.outletIndex,
        detail: cloneShift(ev.shift),
        refresh: ev.refresh,
      };
  }

  cancelEditShift() {
    this.editShift = null;
  }

  saveEditShift(quickSave = false) {
    let saveShift = this.editShift;
    let sDetail = this.editShift.detail;
    //for each shift check conflict
    let conflict = 0;
    let depErrors = 0;
    saveShift.rows.forEach((r, i) => {
      if (this.editShift.details) sDetail = this.editShift.details[i]; //different details for different shifts
      let con = this.checkShiftConflict(r.shifts, sDetail);
      let dep = this.checkDepartmentError(r, sDetail);

      r.validated = !con && !dep;
      if (con) conflict += 1;
      if (dep) depErrors += 1;
    });

    if (conflict || depErrors) {
      if (saveShift.personCount == 1) {
        if (quickSave) {
          this.editShift = null;
        }
        if (conflict) this.uiService.acknowledgeError('Cannot save this shift because it conflicts with another shift for this person.');
        else if (depErrors)
          this.uiService.acknowledgeError('Cannot save this shift because this person is not associated with this department.');
        return;
      } else {
        let msg = '';
        if (conflict)
          msg += conflict + ' of ' + saveShift.personCount + ' shift(s) cannot be saved because it conflicts with another shift.';
        if (depErrors)
          msg += depErrors + ' of ' + saveShift.personCount + ' shifts cannot be saved as they are not in the correct department.';
        this.uiService.showConfirmation({
          title: 'Warning',
          message: msg,
          icon: 'pi-exclamation-triangle',
          options: [
            {
              text: 'Create ' + (saveShift.personCount - conflict) + ' shifts that are possible.',
              icon: 'pi-check',
              class: 'p-button-danger',
              command: () => this.saveEditShiftFinal(),
            },
            {
              text: "Don't create any shifts",
              icon: 'pi-times',
              class: 'p-button-secondary',
            },
          ],
        });
      }
    } else this.saveEditShiftFinal();
  }

  saveEditShiftFinal() {
    let saveShift = this.editShift;
    let sDetail = this.editShift.detail;

    this.editShift = null;
    if (saveShift.detail.type === 'OFF' || saveShift.detail.type === 'ABSENCE') {
      saveShift.detail.breaks = 0;
    }
    //add this shift to the MRU list
    this.local.addRecentlyUsedList('shiftlist', sDetail, (d) => {
      //test to see if shift exists
      if (sDetail.type != d.type || +sDetail.breaks != +d.breaks || sDetail.comments != d.comments) return false;
      let st1 = dayjs(sDetail.start);
      let st2 = dayjs(d.start);
      if (st1.hour() != st2.hour() || st1.minute() != st2.minute()) return false;
      let e1 = dayjs(sDetail.end);
      let e2 = dayjs(d.end);
      if (e1.hour() != e2.hour() || e1.minute() != e2.minute()) return false;
      return true;
    });

    saveShift.rows.forEach((row, i) => {
      if (!row.validated) return; //ignore conflicting elements
      if (saveShift.oldrows && saveShift.ids[i]) {
        //from a different row so we need to remove the old one
        let oldS = saveShift.oldrows[i].shifts;
        let oldSIndex = oldS.findIndex((s) => s.id == saveShift.ids[i]);
        oldS.splice(oldSIndex, 1); //remove this item
      }
      //add a temp shift and later replace
      let shifts = row.shifts; //if we have a different old row reference
      let shiftIndex = shifts.findIndex((s) => s && s.id == saveShift.ids[i]);
      let detail = saveShift.details ? saveShift.details[i] : saveShift.detail;
      if (shiftIndex < 0) shiftIndex = shifts.push(detail) - 1;
      else shifts[shiftIndex] = detail;
      saveShift.refresh();
      this.scheduleData
        .updateShift(row.id, saveShift.ids[i], saveShift, detail, false)
        .pipe(first())
        .subscribe(
          (newShift) => {
            //okay we got a shift back - add it to the person (or overwrite old values)
            row.shifts[shiftIndex] = newShift;
            row.shifts.sort((a, b) => +dayjs(a.start) - +dayjs(b.start));
            saveShift.refresh();
            this.messageService.add({
              id: 'update_schedules',
              key: 'bottom-right',
              severity: 'info',
              summary: `Shift ${saveShift.ids[i] ? 'updated' : 'created'}, schedule status updating...`,
              icon: 'pi-check',
              life: 5000,
            });
            this.log.info('Confirm Added', shiftIndex, shifts);
          },
          (error) => {
            row.shifts.splice(shiftIndex, 1);
            saveShift?.refresh && saveShift.refresh();
            if (error instanceof ApolloError && error.graphQLErrors?.some((err) => err.message.includes('Shift overlaps'))) {
              this.uiService.acknowledgeError('There is an overlapping shift, please reload to ensure you have the latest shifts.');
            } else if (
              error instanceof ApolloError &&
              error.graphQLErrors?.some((err) => err.message.includes('No appropriate contract for this shift'))
            ) {
              this.uiService.acknowledgeError(
                'The employee does not have a contract to do a shift in this slot, please check their contracts.'
              );
            } else {
              this.sentryService.showAndSendError(error, 'Internal error - shift', 'Unable to update shift value, please reload.');
            }
          }
        );
    });
  }

  checkShiftConflict(list: any[], shift) {
    if (shift.type == 'AGENCY') return false; //no conflict for this shift type
    return list.some((s) => {
      if (s.id == shift.id) return false; //can't conflict with yourself
      if (s.start >= shift.end) return false; //starts after shift finishes
      if (s.end <= shift.start) return false; //ends before shift starts
      return true;
    });
  }

  checkDepartmentError(r: any, shift) {
    if (shift.type == 'AGENCY') return false; //no department issue for this shift type
    let deps = [r.currentContract.department, ...(r.currentContract.otherDepartments || [])];
    return !deps.some((d) => {
      return (
        shift.departmentName === d.departmentName &&
        (shift.outletType ? shift.outletIndex == d.outletIndex && shift.outletType == d.outletType : !d.outletType) &&
        (shift.subDeptName ? shift.subDeptName == d.subDeptName : !d.subDeptName)
      );
    });
  }

  removeEditShift() {
    const saveShift = this.editShift;
    if (saveShift.rows.some((r, i) => saveShift.ids[i])) {
      this.uiService.confirmActionDanger('Remove this Shift?', 'Remove', () => {
        this.editShift = null;
        saveShift.rows.forEach((row, i) => {
          if (saveShift.ids[i]) {
            this.scheduleData
              .deleteShift(saveShift.ids[i], saveShift)
              .pipe(first())
              .subscribe(
                (result) => {
                  //okay we removed the shift from the back end
                  let shifts = row.shifts;
                  let p = shifts.findIndex((s) => s.id == saveShift.ids[i]);
                  if (p >= 0) shifts.splice(p, 1);
                  saveShift.refresh();
                  this.messageService.add({
                    id: 'update_schedules',
                    key: 'bottom-right',
                    severity: 'info',
                    summary: 'Shift removed, schedule status updating...',
                    icon: 'pi-check',
                    life: 5000,
                  });
                },
                (error) => {
                  saveShift?.refresh && saveShift.refresh();
                  this.sentryService.showAndSendError(error, 'Internal error - shift', 'Unable to delete shift, please reload.');
                }
              );
          }
        });
      });
    } else {
      this.editShift = null;
    }
  }

  addCellShift(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot add shifts', 'You do not have permission to add shifts on this system.');
      return;
    }
    if (ev.person.leaving && ev.person.leavingDate && dayjs(ev.startDate).isAfter(dayjs(ev.person.leavingDate))) {
      const pastPhrase = dayjs().isAfter(ev.person.leavingDate, 'day') ? 'has been' : 'will be';
      this.uiService.security(
        'Cannot add shift',
        `This employee contract ${pastPhrase} terminated on ${dayjs(ev.person.leavingDate).format('YYYY-MM-DD')}.`
      );
      return;
    }
    let list = this.local.getRecentlyUsedList('shiftlist', 5);

    let addMenu = [
      {
        label: 'Create shift for ' + ev.person.firstName + ' ' + ev.person.lastName,
        icon: 'pi pi-fw pi-plus',
        command: () => {
          this.addShift([ev.person], ev.startDate, ev.department, ev.refresh, ev.departments);
        },
      },
      ...list.map((l) => ({
        label:
          l.type +
          ' ' +
          this.context.isoToContextDate(l.start).format('HH:mm') +
          ' - ' +
          this.context.isoToContextDate(l.end).format('HH:mm') +
          ' (' +
          l.breaks +
          'mins breaks) ' +
          (ev.departments ? l.departmentLabel : '') +
          (l.comments ? ' [' + l.comments.substring(0, 20) + ']' : ''),
        icon: 'pi pi-fw pi-undo',
        command: () => {
          this.addShift([ev.person], ev.startDate, ev.department, ev.refresh, ev.departments, l);
        },
      })),
    ];
    ev.showMenu(addMenu);
  }

  addColShift(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot add or modify shifts', 'You do not have permission to add or modify shifts on this system.');
      return;
    }
    let list = this.local.getRecentlyUsedList('shiftlist', 5);
    let addMenu = [
      {
        label: 'Add shift for ' + ev.persons.length + ' selected employees.',
        icon: 'pi pi-fw pi-plus',
        items: [
          {
            label: 'Create shift ',
            icon: 'pi pi-fw pi-plus',
            command: () => {
              this.addShift(ev.persons, ev.startDate, ev.department, ev.refresh, ev.departments);
            },
          },
          ...list.map((l) => ({
            label:
              l.type +
              ' ' +
              this.context.isoToContextDate(l.start).format('HH:mm') +
              ' - ' +
              this.context.isoToContextDate(l.end).format('HH:mm') +
              ' (' +
              l.breaks +
              'mins breaks) ' +
              (ev.departments ? l.departmentLabel : '') +
              (l.comments ? ' [' + l.comments.substring(0, 20) + ']' : ''),
            icon: 'pi pi-fw pi-undo',
            command: () => {
              this.addShift(ev.persons, ev.startDate, ev.department, ev.refresh, ev.departments, l);
            },
          })),
        ],
      },
      {
        label: 'Remove all  shifts for ' + ev.persons.length + ' selected employees.',
        command: () => this.removeAllShifts(ev.persons, ev.shifts, ev.startDate, ev.refresh),
        icon: 'pi pi-fw pi-minus',
      },
      {
        label: 'Clone shifts for ' + ev.persons.length + ' selected employees.',
        command: () => this.startCloneShifts(ev),
        icon: 'pi pi-fw pi-clone',
      },
    ];
    ev.showMenu(addMenu);
  }

  saveColShift(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot add or modify shifts', 'You do not have permission to add or modify shifts on this system.');
      return;
    }
    this.log.info(ev.colStatus);
    if (!ev.scheduleDay) {
      this.sentryService.showAndSendError(
        new Error('Missing saveColShift event schedule day'),
        'Unable to detect the date, please retry',
        undefined,
        {
          event: JSON.stringify(ev),
        },
        'SAVE_COL_SHIFT_SCHEDULE_DAY'
      );
    } else {
      if (ev.colStatus != 'AWAITING') {
        this.uiService.info('Already saved', 'This day has already been confirmed');
        return;
      }
      this.uiService.confirmAction('Notify GM that all shifts have been scheduled for this day?', () => {
        this.scheduleData.setScheduleSubmitted(ev.department, ev.scheduleDay).subscribe(
          (data) => {
            //saved as requested.
            ev.reload(); //this might change shifts so reload the data
          },
          (error) => {
            if (error?.message?.includes('Access denied')) {
              this.uiService.acknowledgeError('You do not have permission to submit the schedule.', () => {
                ev?.reload();
              });
            } else {
              this.sentryService.showAndSendError(
                error,
                'Unable to submit schedule',
                'An unknown error occurred while trying to submit the schedule.',
                { department: ev.department, scheduleDay: ev.scheduleDay },
                'schedule-shift-save'
              );
              ev?.reload();
            }
          }
        );
      });
    }
  }

  approveColShift(ev) {
    if (!ev.scheduleDay) {
      this.sentryService.showAndSendError(
        new Error('Missing approveColShift event schedule day'),
        'Unable to detect the date, please retry',
        undefined,
        {
          event: JSON.stringify(ev),
        },
        'APPROVE_COL_SHIFT_SCHEDULE_DAY'
      );
    } else {
      this.uiService.confirmAction('Approve shift schedule for this day?', () => {
        this.scheduleData.setScheduleApproved(ev.department, ev.scheduleDay).subscribe(
          (data) => {
            //saved as requested.
            ev.reload(); //this might change shifts so reload the data
          },
          (error) => {
            if (error?.message?.includes('Access denied')) {
              this.uiService.acknowledgeError('You do not have permission to approve the schedule.', () => {
                ev?.reload();
              });
            } else {
              this.sentryService.showAndSendError(
                error,
                'Unable to approve schedule',
                'An unknown error occurred while trying to approve the schedule.',
                { department: ev.department, scheduleDay: ev.scheduleDay },
                'schedule-shift-approve'
              );
              ev?.reload();
            }
          }
        );
      });
    }
  }

  removeAllShifts(persons, shifts, onDate, refresh) {
    this.uiService.confirmActionDanger(
      'Remove all shifts for ' + persons.length + ' employees? (' + shifts.length + ' shifts)',
      'Remove',
      () => {
        this.removeAllShiftsOnDate(persons, shifts, refresh);
      }
    );
  }

  removeAllShiftsOnDate(persons, shifts, refresh) {
    shifts.forEach((sht) => {
      this.scheduleData
        .deleteShift(sht.id, sht)
        .pipe(first())
        .subscribe(
          (result) => {
            //okay we removed the shift from the back end
            let shifts = sht.person.shifts;
            let p = shifts.findIndex((s) => s.id == sht.id);
            if (p >= 0) shifts.splice(p, 1);
            refresh();
          },
          (error) => {
            refresh && refresh();
            this.sentryService.showAndSendError(error, 'Internal error - shift', 'Unable to remove shifts, please reload.');
          }
        );
    });
  }

  approveAll(ev) {
    if (!ev.scheduleDay || !ev.daysShown) {
      this.sentryService.showAndSendError(
        new Error('Missing approveAll event schedule day'),
        'Unable to detect the dates, please retry',
        undefined,
        {
          event: JSON.stringify(ev),
        },
        'APPROVE_ALL_SCHEDULE_DAY'
      );
    } else {
      let badCount = ev.allColStatus.reduce((a, e) => (e == 'AWAITING' ? a + 1 : a), 0);
      let message = 'Approve shift schedule for all days?';
      if (badCount) message += ` (${badCount} days have not been saved by the HoD)`;
      this.uiService.confirmAction(message, () => {
        //build an array of requests
        this.uiService.info('Approving shifts');
        this.scheduleData.setScheduleApprovedForDays(ev.department, ev.scheduleDay, ev.daysShown).subscribe(
          (data) => {
            //saved as requested.
            ev.reload(); //this might change shifts so reload the data
            this.uiService.info('Shifts Approved');
          },
          (error) => {
            if (error?.message?.includes('Access denied')) {
              this.uiService.acknowledgeError('You do not have permission to approve the shifts.', () => {
                ev?.reload();
              });
            } else {
              this.sentryService.showAndSendError(
                error,
                'Unable to approve schedule',
                'An unknown error occurred while trying to approve the schedule.',
                { department: ev.department, scheduleDay: ev.scheduleDay, daysShown: ev.daysShown },
                'schedule-shift-approve-all'
              );
              ev?.reload();
            }
          }
        );
      });
    }
  }

  saveAll(ev) {
    if (!ev.scheduleDay || !ev.daysShown) {
      this.sentryService.showAndSendError(
        new Error('Missing saveAll event schedule day'),
        'Unable to detect the dates, please retry',
        undefined,
        {
          event: JSON.stringify(ev),
        },
        'SAVE_ALL_SCHEDULE_DAY'
      );
    } else {
      this.uiService.confirmAction('Notify GM that all shifts have been scheduled for all days?', () => {
        this.uiService.info('Saving shifts');
        runAndRetry(this.scheduleData.setScheduleSubmittedForDays(ev.department, ev.scheduleDay, ev.daysShown)).subscribe(
          (data) => {
            ev.reload(); //this might change shifts so reload the data
            this.uiService.info('Shifts saved');
          },
          (error) => {
            if (error?.message?.includes('Access denied')) {
              this.uiService.acknowledgeError('You do not have permission to submit the shifts.', () => {
                ev?.reload();
              });
            } else {
              this.sentryService.showAndSendError(
                error,
                'Unable to submit schedule',
                'An unknown error occurred while trying to submit the schedule.',
                { department: ev.department, scheduleDay: ev.scheduleDay, daysShown: ev.daysShown },
                'schedule-shift-save-all'
              );
              ev?.reload();
            }
          }
        );
      });
    }
  }

  async export(ev) {
    this.xls.startSheet();
    this.xls.addTitleRow(ev.department.label + ' (' + dayjs().format('DD/MM/YYYY') + ')', 'FFFFFF', true, 3);
    this.xls.setColumnWidths([25, 15, 15, ...new Array(ev.daysShown).fill(12)]);
    //go through each day and make a header row
    let sd = dayjs(ev.startDate);

    let dates = new Array(ev.daysShown).fill(0).map((v, i) => new Date(sd.add(i, 'day').format('YYYY-MM-DD')));
    let dateD = new Array(ev.daysShown).fill(0).map((v, i) => sd.add(i, 'day').format('dddd'));
    this.xls.addRow(['Employee', 'T&A ID', 'Business Title', 'Other Department', ...dates], 'BBBBBB');
    this.xls.addRow(['', '', '', '', ...dateD], 'BBBBBB');
    //go through the people
    ev.allPersons.forEach((person, pIndex) => {
      if (person.isAgency) return;
      if (person.drawHeader) {
        //if it is a header row add the header row
        let ht = '';
        switch (person.pType) {
          case 0:
            ht = 'Home Department';
            break;
          case 1:
            ht = 'Other Department';
            break;
          case 2:
            ht = '0 Hour hotel Employees';
            break;
          case 3:
            ht = 'Agency';
            break;
        }
        this.xls.addTitleRow(ht, 'A1C6C8', 3 + ev.dayShown);
      }
      //go through the shifts and add them to the first available row they fit on
      const shiftRows = [];
      person.shifts
        .sort((a, b) => {
          if (a.otherDep && !b.otherDep) return 1;
          if (b.otherDep && !a.otherDep) return -1;
          return a.start > b.start ? 1 : -1;
        })
        .forEach((s) => {
          //figure out which columns it's in
          let ss = this.context.isoToContextDate(s.start).startOf('day');
          let sc = dates.findIndex((d) => ss.isSame(dayjs(d), 'day'));
          let sdep = s.otherDep ? s.departmentLabel : '';
          if (sc < 0) return; //not on this report
          //figure out which row it's in starting at the top
          let rowNum = 0;
          while (true) {
            if (shiftRows.length <= rowNum)
              //add a new row
              shiftRows.push({
                cells: new Array(ev.daysShown).fill(''),
                department: sdep,
              });
            let row = shiftRows[rowNum].cells;
            let dep = shiftRows[rowNum].department;
            if (row[sc] == '' && dep == sdep) {
              //add shift to the row in this spot
              let text = s.type;
              if (['WORK', 'APPRENTICE', 'TRAINING'].includes(s.type)) {
                //render the times
                text =
                  this.context.isoToContextDate(s.start).format('HH:mm') + ' - ' + this.context.isoToContextDate(s.end).format('HH:mm');
                if (s.comments) text += ' (' + s.comments + ')';
              } else if (s.type == 'ABSENCE') text = this.aTypes[s.absenceType] ? this.aTypes[s.absenceType].short : '??';
              row[sc] = text;
              break;
            }
            rowNum += 1;
          }
        });
      //Okay now output the rows
      let sr = 0;
      shiftRows.forEach((r, i) => {
        let row = this.xls.addRow(
          [
            i ? '' : person.firstName + ' ' + person.lastName,
            i ? '' : person.personnelNo,
            i || !person.currentContract ? '' : person.currentContract.title,
            r.department,
            ...r.cells,
          ],
          pIndex % 2 ? 'FFFFFF' : 'EEEEEE'
        );
        if (!i) sr = row.number; //keep the start row for merges
      });
      if (shiftRows.length > 1) {
        this.xls.mergeCells(sr, 1, sr + shiftRows.length - 1, 1);
        this.xls.mergeCells(sr, 2, sr + shiftRows.length - 1, 2);
        this.xls.setVAlignMiddle(sr, 1);
        this.xls.setVAlignMiddle(sr, 2);
      }
    });

    this.xls.setColumnAlignment(1, 4 + ev.daysShown, 'left', 'top', true);

    const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';
    const filename = `schedule-${holidex}-${ev.department?.label}-${ev.scheduleDay}.xlsx`.replace(/ /g, '-');
    await this.xls.outputSheet(filename);
  }

  shiftMoved(ev) {
    if (ev.person.id != ev.oldperson.id) {
      //check if this is a move between agency and non agency

      if (!!ev.person.agency != !!ev.oldperson.agency) {
        this.uiService.error('Cannot move shift', 'Shift cannot be moved between named agency and non-agency employees');
        return;
      }
      if (!!ev.person.isAgency != !!ev.oldperson.isAgency) {
        this.uiService.error('Cannot move shift', 'Shift cannot be moved between unnamed agency and other employees');
        return;
      }
      //Check for bad moves
      ev.shift.personId = ev.person.id; //force to correct person
    }
    let shift = cloneShift(ev.shift);

    if (ev.forceCopy) shift.id = 0;

    let s = this.context.isoToContextDate(shift.start);
    let e = this.context.isoToContextDate(shift.end);
    let dayOffset = s.date() != e.date() ? 1 : 0;
    shift.start = this.context.contextDateToISO(dayjs.utc(ev.startDate).hour(s.hour()).minute(s.minute()));
    shift.end = this.context.contextDateToISO(dayjs.utc(ev.startDate).add(dayOffset, 'day').hour(e.hour()).minute(e.minute()));

    this.editShift = {
      rows: [ev.person],
      oldrows: [ev.oldperson],
      ids: [shift.id],
      personCount: 1,
      departmentName: ev.department.name,
      subDeptName: ev.department.subDeptName,
      departments: ev.departments,
      outletType: ev.department.outletType,
      outletIndex: ev.department.outletIndex,
      detail: shift,
      refresh: ev.refresh,
    };
    this.saveEditShift(true);
  }

  startCloneShifts(ev) {
    this.cloneEv = ev;
  }

  saveCloneShifts(datelist) {
    var content = this.cloneEv;
    this.cloneEv = null;
    let sDate = dayjs.utc(content.startDate);
    //for each source start building out the targets
    let personList = [];
    let shiftList = [];
    //make a list of source shifts
    content.persons.forEach((per) => {
      //find shifts on the startdate
      let shifts = per.shifts.filter(
        (
          s //check if the shifts start on this day
        ) => dayjs.utc(s.start).isSame(sDate, 'day')
      );
      //for each shift for each day add a record
      shifts.forEach((os) => {
        datelist.forEach((d) => {
          let ns = cloneShift(os);
          let s = this.context.isoToContextDate(os.start);
          let e = this.context.isoToContextDate(os.end);
          let dayOffset = s.date() != e.date() ? 1 : 0;
          ns.start = this.context.contextDateToISO(dayjs.utc(d).hour(s.hour()).minute(s.minute()));
          ns.end = this.context.contextDateToISO(dayjs.utc(d).add(dayOffset, 'day').hour(e.hour()).minute(e.minute()));
          //add to the lists
          personList.push(per);
          shiftList.push(ns);
        });
      });
    });

    if (shiftList?.length > 0) {
      //try save them
      this.editShift = {
        rows: personList,
        ids: new Array(personList.length).fill(0),
        personCount: personList.length,
        personLabel: 'Clone',
        departmentLabel: content.department.label,
        departmentName: content.department.name,
        subDeptName: content.department.subDeptName,
        departments: content.departments,
        outletType: content.department.outletType,
        outletIndex: content.department.outletIndex,
        detail: cloneShift(shiftList[0]),
        details: shiftList,
        refresh: content.refresh,
      };
      this.saveEditShift();
    } else {
      this.uiService.acknowledgeError(`There is no shift on ${sDate.format('D MMM')} to clone from.`);
    }
  }

  cancelCloneShifts() {
    this.cloneEv = null;
  }
}
