import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ContextService } from 'src/services/context.service';

import dayjs from 'dayjs';
import { ICheckin } from '../shift-display/shift-display.component';
import { LoggingService } from 'src/services/logging.service';

@Component({
  selector: 'app-checkin-marker',
  styleUrls: ['./checkin-marker.component.scss'],
  templateUrl: './checkin-marker.component.html',
})
export class CheckInMarkerComponent implements OnInit {
  @Input() checkIn: ICheckin;
  @Input() timeStart = 0;
  @Input() cellStart = 0;
  @Input() timeEnd = 0;
  @Input() timeWidth = 1;
  @Input() blockMode = false;
  @Input() displayIndex;

  public startPos = 10;
  public lineLength = 0;
  public width = 100;
  public start: dayjs.Dayjs;
  public showFade = false;

  constructor(private context: ContextService, private log: LoggingService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.start = this.context.isoToContextDate(this.checkIn.time);

    let astart = +this.start;

    if (this.blockMode) {
      this.startPos = this.displayIndex * 10;
      this.lineLength = this.checkIn.length ? 10 : 0;
    } else {
      this.startPos = ((+astart - this.cellStart) * 100) / this.timeWidth;
      this.lineLength = (this.checkIn.length * 24 * 60 * 60 * 1000 * 100) / this.timeWidth;
    }

    if (!this.lineLength && this.checkIn.checkInType == 'IN') {
      this.showFade = true;
    }
  }

  toolTip() {
    const dt = this.start.format('HH:mm:ss');
    let tt = this.checkIn.checkInType == 'IN' ? `Checked in at ${dt}.` : `Checked out at ${dt}.`;
    if (this.checkIn.length) {
      const hours = Math.floor(this.checkIn.length * 24);
      const minutes = Math.round((this.checkIn.length * 24 - hours) * 60);
      tt += `\nLength ${hours} hours${minutes >= 1 ? ' ' + String(minutes > 59 ? 59 : minutes).padStart(2, '0') + ' mins' : ''}.`;
    }
    if (this.showFade) tt += '\nNo check out.';
    if (this.checkIn.shiftMatch === 'BAD') tt += '\n[BAD MATCH]';
    if (this.checkIn.shiftMatch === 'NOMATCH') tt += '\n[NO MATCH]';
    return tt;
  }
}
