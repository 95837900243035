import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { cloneShift, Right, runAndRetry, Scope } from 'src/commontypes/util';

import { ScheduleDataService } from 'src/services/schedule-data.service';
import dayjs from 'dayjs';

import { UIService } from 'src/services/ui.service';
import { catchError, distinctUntilChanged, first, map, mergeMap, tap } from 'rxjs/operators';
import { SentryService } from 'src/services/sentry.service';
import { AuthService } from 'src/services/auth.service';
import { forkJoin, of, Subscription, zip } from 'rxjs';
import { LoggingService } from 'src/services/logging.service';
import { ContextService } from 'src/services/context.service';
import { XLSService } from 'src/services/xls.service';
import { getAbsenceTypeLookUp } from 'src/commontypes/shifts';

@Component({
  selector: 'app-capture-shifts',
  styleUrls: ['./captureshifts.component.scss'],
  templateUrl: './captureshifts.component.html',
})
export class CaptureShiftsComponent implements OnInit, OnDestroy {
  editShift = null;
  disableEditShift = false;
  canWrite = false;
  canSubmit = false;
  canApprove = false;
  role$: Subscription;
  arole$: Subscription;
  submit$: Subscription;
  lastEv: any = null;

  public aTypes = getAbsenceTypeLookUp();

  @ViewChild('uploadarea') uploadControl;

  constructor(
    public scheduleData: ScheduleDataService,
    private uiService: UIService,
    private sentryService: SentryService,
    private authService: AuthService,
    private context: ContextService,
    private log: LoggingService,
    private xls: XLSService,
    private ui: UIService
  ) { }

  ngOnInit() {
    this.role$ = this.authService
      .hasAnyRoles([
        [Scope.SCHEDULE_CAPTURE, Right.WRITE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.REGION_ADMIN, Right.WRITE],
      ])
      .pipe(distinctUntilChanged())
      .subscribe((v) => (this.canWrite = v));
    this.submit$ = this.authService
      .hasAnyRoles([
        [Scope.SCHEDULE_CAPTURE, Right.WRITE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.REGION_ADMIN, Right.WRITE],
      ])
      .pipe(distinctUntilChanged())
      .subscribe((v) => (this.canSubmit = v));
    this.arole$ = this.authService
      .hasAnyRoles([
        [Scope.SCHEDULE_CAPTURE, Right.APPROVE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.REGION_ADMIN, Right.WRITE],
      ])
      .pipe(distinctUntilChanged())
      .subscribe((v) => (this.canApprove = v));
  }

  ngOnDestroy() {
    if (this.role$) {
      this.role$.unsubscribe();
      this.role$ = undefined;
    }
    if (this.submit$) {
      this.submit$.unsubscribe();
      this.submit$ = undefined;
    }
  }

  modifyShift(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot update shifts', 'You do not have permission to update shifts on this system.');
      return;
    }
    if (ev.colStatus == 'APPROVED') {
      const showShift = () => {
        let shift = cloneShift(ev.shift);
        this.disableEditShift = true;
        this.editShift = {
          row: ev.person,
          id: ev.shift.id,
          personLabel: ev.person.firstName + ' ' + ev.person.lastName,
          departmentLabel: ev.department.label,
          departmentName: ev.department.name,
          subDeptName: ev.department.subDeptName,
          outletType: ev.department.outletType,
          outletIndex: ev.department.outletIndex,
          detail: shift,
          refresh: ev.refresh,
        };
      };
      showShift.bind(this);
      this.uiService.acknowledgeError(
        'This day has been approved and locked - shifts cannot be changed. To make any changes, unlock the day by clicking on the green lock icon.',
        showShift,
        'Locked'
      );
    } else if (ev.shift.otherDep) {
      this.uiService.acknowledgeError('This shift is not for the selected department and cannot be captured.');
    } else {
      let shift = cloneShift(ev.shift);
      this.disableEditShift = false;
      this.editShift = {
        row: ev.person,
        id: ev.shift.id,
        personLabel: ev.person.firstName + ' ' + ev.person.lastName,
        departmentLabel: ev.department.label,
        departmentName: ev.department.name,
        subDeptName: ev.department.subDeptName,
        outletType: ev.department.outletType,
        outletIndex: ev.department.outletIndex,
        detail: shift,
        refresh: ev.refresh,
      };
    }
  }

  addCellShift(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot add new shifts', 'You do not have permission to add unplanned shifts on this system.');
      return;
    }
    if (ev.colStatus == 'APPROVED') {
      this.uiService.security('Cannot add new shifts', 'This day has been locked and new shfts cannot be added.');
      return;
    }

    this.uiService.confirmAction('Add an unplanned shift?', () => {
      this.addUnplannedShift(ev.person, ev.startDate, ev.department, ev.refresh);
    });
  }

  colMenu(ev) { }

  colSaveMenu(ev) {
    if (!this.canWrite) {
      this.uiService.security('Cannot modify shifts', 'You do not have permission to update or submit shifts on this system.');
      return;
    }
    let numBadShifts = ev.allShifts.reduce((a, s) => (s.capturedShifts[0]?.status == 'PRE_DRAFT' ? a + 1 : a), 0);
    if (numBadShifts) {
      this.uiService.acknowledgeError(`${numBadShifts} shift(s) on this day require explicit confirmation before submitting actuals.`);
      return;
    }
    //check for a bad status capture
    this.uiService.confirmAction('Confirm all actual working and absence hours have been entered correctly for this day?', () => {
      this.submitShifts(ev, ev.startDate, ev.allShifts).subscribe(() => ev.reload());
    });
  }

  submitShifts(ev, startDate, shifts) {
    let uncapturedShifts = shifts.filter((s) => !s.capturedShifts?.length);
    if (uncapturedShifts.some((s) => s.type === 'AGENCY' && !s.quantity && !s.capturedShifts?.length && !s.person?.id)) {
      this.uiService.acknowledgeError('Some agency shifts with zero members exist, cannot submit the shifts.');
      return of([]);
    } else {
      this.uiService.info('Updating Shifts for this day');
      return this.completeShifts(uncapturedShifts).pipe(
        mergeMap((data) => {
          this.uiService.info('Updating Capture Status');
          return runAndRetry(this.scheduleData.setScheduleCaptureSubmitted(ev.department, startDate));
        }),
        tap(() => {
          this.uiService.info('Capture updated');
        })
      );
    }
  }

  colApprove(ev) {
    this.uiService.confirmAction('Lock all actual working and absence hours?', () => {
      this.uiService.info('Locking Day');
      runAndRetry(this.scheduleData.setScheduleCaptureApproved(ev.department, ev.startDate)).subscribe(
        (data) => {
          this.uiService.info('Day Locked - reloading shifts');
          //saved as requested.
          ev.reload(); //this might change shifts so reload the data
        },
        (error) => {
          if (error?.message?.includes('Access denied')) {
            this.uiService.acknowledgeError('You do not have permission to lock the schedule.', () => {
              ev?.reload();
            });
          } else {
            this.sentryService.showAndSendError(
              error,
              'Unable to lock schedule',
              'An unknown error occurred while trying to lock the schedule.',
              { department: ev?.department, startDate: ev.startDate },
              'capture-shift-approve'
            );
            ev?.reload();
          }
        }
      );
    });
  }

  colUnapprove(ev) {
    this.uiService.confirmAction('Unlock actual working and absence hours to allow edit?', () => {
      this.uiService.info('Unlocking Day');
      this.scheduleData
        .setScheduleCaptureUnapproved(
          ev.department.name,
          ev.department.subDeptName,
          ev.department.outletType,
          ev.department.outletIndex,
          ev.startDate
        )
        .subscribe(
          (data) => {
            this.uiService.info('Day unlocked - reloading shifts');
            ev.reload(); //this might change shifts so reload the data
          },
          (error) => {
            if (error?.message?.includes('Access denied')) {
              this.uiService.acknowledgeError('You do not have permission to unlock the schedule.', () => {
                ev?.reload();
              });
            } else {
              this.sentryService.showAndSendError(
                error,
                'Unable to unlock schedule',
                'An unknown error occurred while trying to unlock the schedule.',
                { department: ev?.department, startDate: ev.startDate },
                'capture-shift-unapprove'
              );
              ev?.reload();
            }
          }
        );
    });
  }

  completeShifts(shifts: any[]) {
    if (shifts.length == 0) {
      //no shfts for this day
      return of([true]);
    }
    let updates = shifts.map((s) => {
      //add the captured correctly
      let capturedShift = {
        id: 0,
        start: s.start,
        end: s.end,
        breaks: s.breaks,
        quantity: s.quantity,
        absence: false,
        absenceComment: null as string,
        absenceType: null,
        status: 'DRAFT',
      };
      return this.scheduleData.updateCapturedShift(s.id, capturedShift).pipe(
        first(),
        map((newCapShift) => {
          //okay we got a captured shift back - add it to the person (or overwrite old values)
          let shifts = s.person.shifts;
          let newShift = {
            ...s,
            capturedShifts: [newCapShift],
          };
          delete newShift.person; //stops circular references on living shifts
          let i = shifts.findIndex((s2) => s2.id == s.id);
          if (i < 0) shifts.push(newShift);
          else shifts[i] = newShift;
          return newCapShift;
        }),
        catchError((error) => {
          if (error?.message?.includes('mismatched') || error?.message === 'Shift already has a captured shift') {
            this.uiService.confirmActionDanger(
              'The actual hours have been updated on the server, please reload and then re-submit',
              'Refresh',
              () => window.location.reload(),
              undefined,
              'Actual shifts mismatch',
              'Reload'
            );
          }
          throw error;
        })
      );
    });
    return forkJoin(updates);
  }

  addUnplannedShift(row, start, dep, refresh) {
    const {
      currentContract: { contractedHours, workDays },
    } = row || { currentContract: { contractedHours: 40, workDays: 5 } };
    const defaultBreak = 30;

    let shiftSetup = {
      type: 'WORK',
      start: this.context.contextDateToISO(dayjs.utc(start).hour(8).minute(0)),
      end: this.context.contextDateToISO(
        dayjs
          .utc(start)
          .hour(8)
          .add(contractedHours / (workDays || 1) || 8, 'hour')
          .add(defaultBreak, 'minute')
      ),
      breaks: 30,
      comments: '',
      quantity: 1,

      capturedShifts: [],
    };

    if (row.isAgency || row.agency) shiftSetup.type = 'AGENCY'; //always force agency type for these

    this.disableEditShift = false;
    this.editShift = {
      row,
      id: 0,
      personLabel: row.firstName + ' ' + row.lastName,
      departmentLabel: dep.label,
      departmentName: dep.name,
      subDeptName: dep.subDeptName,
      outletType: dep.outletType,
      outletIndex: dep.outletIndex,
      detail: shiftSetup,
      refresh,
    };
  }

  cancelEditShift() {
    this.disableEditShift = false;
    this.editShift = null;
  }

  checkShiftConflict(list: any[], shift) {
    if (shift.type == 'AGENCY') return false; //no conflict for this shift type
    return list.some((s) => {
      if (s.id == shift.id) return false; //can't conflict with yourself
      if (s.start >= shift.end) return false; //starts after shift finishes
      if (s.end <= shift.start) return false; //ends before shift starts
      if (s.capturedShifts?.length) {
        let cs = s.capturedShifts[0];
        if (cs.cancel) return false;
        if (cs.start >= shift.end) return false; //starts after shift finishes
        if (cs.end <= shift.start) return false; //ends before shift starts
      }
      return true;
    });
  }

  saveEditShift() {
    let saveShift = this.editShift;
    const cancelledShift = !!saveShift?.detail?.capturedShifts?.every((c) => c.cancel);
    this.disableEditShift = false;
    this.editShift = null;

    if (saveShift.detail.type === 'OFF' || saveShift.detail.type === 'ABSENCE') {
      saveShift.detail.breaks = 0;
      saveShift.detail.capturedShifts[0].breaks = 0;
    }
    if (saveShift.detail.id)
      //we have a legit shift
      this.updateCapture(saveShift);
    else if (!cancelledShift) {
      //unplanned shift - we need to check for conflicts
      if (this.checkShiftConflict(saveShift.row.shifts, saveShift.detail)) {
        this.uiService.acknowledgeError('Cannot save this shift because it conflicts with another shift for this person.');
        return;
      }

      //we need to make a shift first to match the capture
      let s = {
        departmentName: saveShift.departmentName,
        outletIndex: saveShift.outletIndex,
        outletType: saveShift.outletType,
        subDeptName: saveShift.subDeptName,
        ...saveShift.detail,
      };
      let c = s.capturedShifts[0];
      s.start = c.start;
      s.end = c.end;
      s.breaks = c.breaks;

      this.scheduleData
        .updateShift(saveShift.row.id, 0, saveShift, saveShift.detail, true)
        .pipe(first())
        .subscribe(
          (newShift) => {
            //okay we got a shift back - add it to the person (or overwrite old values)
            newShift.capturedShifts = [saveShift.detail.capturedShifts[0]];
            saveShift.detail = newShift;
            this.updateCapture(saveShift);
            let shifts = saveShift.row.shifts;
            let i = shifts.findIndex((s) => s.id == newShift.id);
            if (i < 0) shifts.push(newShift);
            else shifts[i] = newShift;
            shifts.sort((a, b) => +dayjs(a.start) - +dayjs(b.start));
            saveShift.refresh();
          },
          (error) => {
            saveShift?.refresh && saveShift.refresh();
            this.sentryService.showAndSendError(
              error,
              'Internal error - shift',
              'Unable to update shift, please reload.',
              undefined,
              'captureshift-save-error'
            );
          }
        );
    }
  }

  updateCapture(saveShift) {
    const capturedShift = saveShift.detail.capturedShifts[0];
    if (!capturedShift) {
      this.sentryService.showAndSendError(
        new Error('No captured shift for update'),
        'Internal error - actual shift missing',
        'Please reload and try again',
        { detail: saveShift.detail },
        'captureshift-missing'
      );
    }
    capturedShift.state = capturedShift.state === 'PRE_DRAFT' ? 'DRAFT' : capturedShift.state;
    if (saveShift.detail.capturedShifts.length > 1) {
      this.sentryService.sendError(
        new Error('More than one capture shift'),
        { shift: saveShift.detail.id, capShifts: saveShift.detail.capturedShifts.map((c) => c.id) },
        'captureshift-extra-capture'
      );
    }
    this.scheduleData
      .updateCapturedShift(saveShift.detail.id, capturedShift)
      .pipe(first())
      .subscribe(
        (newCapShift) => {
          //okay we got a captured shift back - add it to the person (or overwrite old values)
          this.log.info(newCapShift);
          let shifts = saveShift.row.shifts;
          let newShift = saveShift.detail;
          newShift.capturedShifts[0] = newCapShift;
          let i = shifts.findIndex((s) => s.id == saveShift.detail.id);
          if (i < 0) shifts.push(newShift);
          else shifts[i] = newShift;
          saveShift.refresh();
        },
        (error) => {
          saveShift?.refresh && saveShift.refresh();
          this.sentryService.showAndSendError(error, 'Internal error - shift', 'Unable to update time sheet entry, please reload.', {
            shift: saveShift.detail.id,
            capturedShift,
          });
        }
      );
  }

  submitAll(ev) {
    this.uiService.confirmAction('Confirm all actual working and absence hours have been entered correctly?', () => {
      //build an array of requests
      this.submitAllShifts(ev, ev.startDate, ev.daysShown, ev.allShifts).subscribe(
        (data) => {
          ev.reload(); //this might change shifts so reload the data
        },
        (error) => {
          if (error?.message?.includes('Access denied')) {
            this.uiService.acknowledgeError('You do not have permission to submit the shifts.', () => {
              ev?.reload();
            });
          } else {
            this.sentryService.showAndSendError(
              error,
              'Unable to submit shifts',
              'An unknown error occurred while trying to submit the shifts.',
              { department: ev.department, startDate: ev.startDate, daysShown: ev.daysShown },
              'schedule-shift-save-all'
            );
            ev?.reload();
          }
        }
      );
    });
  }

  lockAll(ev) {
    if (!ev.daysShown) {
      this.sentryService.showAndSendError(
        new Error('Missing lockAll event days shown'),
        'Unable to detect the dates, please retry',
        undefined,
        {
          event: JSON.stringify(ev),
        },
        'LOCK_ALL_DAYS_SHOWN'
      );
    } else {
      let badCount = ev.allColStatus.reduce((a, e) => (e == 'AWAITING' ? a + 1 : a), 0);
      if (badCount > 0) {
        this.uiService.acknowledgeError('Cannot lock some days. ' + badCount + ' day(s) have not been submitted.');
        return;
      }
      this.uiService.confirmAction('Lock all actual working and absence hours?', () => {
        this.uiService.info('Locking Days');
        runAndRetry(this.scheduleData.setScheduleCaptureApprovedForDays(ev.department, ev.startDate, ev.daysShown)).subscribe(
          (data) => {
            this.uiService.info('Days locked - reloading shifts');
            //saved as requested.
            ev.reload(); //this might change shifts so reload the data
          },
          (error) => {
            if (error?.message?.includes('Access denied')) {
              this.uiService.acknowledgeError('You do not have permission to lock the shifts.', () => {
                ev?.reload();
              });
            } else {
              this.sentryService.showAndSendError(
                error,
                'Unable to lock shifts',
                'An unknown error occurred while trying to lock the shifts.',
                { department: ev.department, startDate: ev.startDate, daysShown: ev.daysShown },
                'schedule-shift-save-all'
              );
              ev?.reload();
            }
          }
        );
      });
    }
  }

  submitAllShifts(ev, startDate, numDays, shifts) {
    const start = this.context.contextDateToDate(dayjs(startDate));
    const end = start.add(numDays || 1, 'days');
    const submittedShifts = shifts.filter((s) => start.isBefore(s.shiftDay) && end.isAfter(s.shiftDay));
    const uncapturedShifts = submittedShifts.filter((s) => !s.capturedShifts?.length);
    if (uncapturedShifts.some((s) => s.type === 'AGENCY' && !s.quantity && !s.capturedShifts?.length && !s.person?.id)) {
      this.uiService.acknowledgeError('Some agency shifts with zero members exist, cannot submit the shifts.');
      return of([]);
    } else {
      this.uiService.info('Updating Shifts for this day');
      return this.completeShifts(uncapturedShifts).pipe(
        mergeMap(() => {
          this.uiService.info('Updating Capture Status');
          return runAndRetry(this.scheduleData.setScheduleCaptureSubmittedForDays(ev.department, startDate, numDays), true);
        }),
        tap(() => {
          this.uiService.info('Capture updated');
        })
      );
    }
  }

  async export(ev) {
    this.lastEv = ev;
    let menu = [
      {
        label: 'Export Shift Sheet',
        icon: 'pi pi-download',
        command: () => {
          this.exportShiftSheet(ev);
        },
      },
      {
        label: 'Create Actuals Import Template',
        icon: 'pi pi-download',
        command: () => {
          this.exportActualsTemplate(ev);
        },
      },
      {
        label: 'Import Actuals Template',
        icon: 'pi pi-upload',
        command: () => {
          this.uploadControl.nativeElement.click();
        },
      },
    ];
    ev.showMenu(menu);
  }

  async exportShiftSheet(ev) {
    this.xls.startSheet();
    this.xls.addTitleRow(ev.department.label + ' (' + dayjs().format('DD/MM/YYYY') + ')', 'FFFFFF', true, 3);
    this.xls.setColumnWidths([25, 12, 15, 15, ...new Array(ev.daysShown).fill(12)]);
    //go through each day and make a header row
    let sd = dayjs(ev.startDate);

    let dates = new Array(ev.daysShown).fill(0).map((v, i) => new Date(sd.add(i, 'day').format('YYYY-MM-DD')));
    let dateD = new Array(ev.daysShown).fill(0).map((v, i) => sd.add(i, 'day').format('dddd'));
    this.xls.addRow(['Employee', 'T&A ID', 'Business Title', 'Other Department', ...dates], 'BBBBBB');
    this.xls.addRow(['', '', '', '', ...dateD], 'BBBBBB');
    //go through the people
    ev.allPersons.forEach((person, pIndex) => {
      if (person.isAgency) return;
      if (person.drawHeader) {
        //if it is a header row add the header row
        let ht = '';
        switch (person.pType) {
          case 0:
            ht = 'Home Department';
            break;
          case 1:
            ht = 'Other Department';
            break;
          case 2:
            ht = '0 Hour hotel Employees';
            break;
          case 3:
            ht = 'Agency';
            break;
        }
        this.xls.addTitleRow(ht, 'A1C6C8', 3 + ev.dayShown);
      }
      //go through the shifts and add them to the first available row they fit on
      const shiftRows = [];
      person.shifts
        .sort((a, b) => {
          if (a.otherDep && !b.otherDep) return 1;
          if (b.otherDep && !a.otherDep) return -1;
          return a.start > b.start ? 1 : -1;
        })
        .forEach((s) => {
          //figure out which columns it's in
          let ss = dayjs(s.start).startOf('day');
          let sc = dates.findIndex((d) => ss.isSame(dayjs(d), 'day'));
          let sdep = s.otherDep ? s.departmentLabel : '';
          if (sc < 0) return; //not on this report
          //figure out which row it's in starting at the top
          let rowNum = 0;
          while (true) {
            if (shiftRows.length <= rowNum)
              //add a new row
              shiftRows.push({
                cells: new Array(ev.daysShown).fill(''),
                department: sdep,
              });
            let row = shiftRows[rowNum].cells;
            let dep = shiftRows[rowNum].department;
            if (row[sc] == '' && dep == sdep) {
              //add shift to the row in this spot
              let text = s.type;
              if (['WORK', 'APPRENTICE', 'TRAINING'].includes(s.type)) {
                let cs = s.capturedShifts[0];
                //render the times
                if (cs) {
                  if (cs.absenceType)
                    //actually absent
                    text = this.aTypes[cs.absenceType] ? this.aTypes[cs.absenceType].short : '??';
                  else
                    text =
                      this.context.isoToContextDate(cs.start).format('HH:mm') +
                      ' - ' +
                      this.context.isoToContextDate(cs.end).format('HH:mm');
                } else text = 'NOT CAPTURED';
                if (s.comments) text += ' (' + s.comments + ')';
              } else if (s.type == 'ABSENCE') text = this.aTypes[s.absenceType] ? this.aTypes[s.absenceType].short : '??';
              row[sc] = text;
              break;
            }
            rowNum += 1;
          }
        });
      //Okay now output the rows
      let sr = 0;
      shiftRows.forEach((r, i) => {
        let row = this.xls.addRow(
          [
            i ? '' : person.firstName + ' ' + person.lastName,
            i ? '' : person.personnelNo,
            i || !person.currentContract ? '' : person.currentContract.title,
            r.department,
            ...r.cells,
          ],
          pIndex % 2 ? 'FFFFFF' : 'EEEEEE'
        );
        if (!i) sr = row.number; //keep the start row for merges
      });
      if (shiftRows.length > 1) {
        this.xls.mergeCells(sr, 1, sr + shiftRows.length - 1, 1);
        this.xls.mergeCells(sr, 2, sr + shiftRows.length - 1, 2);
        this.xls.setVAlignMiddle(sr, 1);
        this.xls.setVAlignMiddle(sr, 2);
      }
    });

    this.xls.setColumnAlignment(1, 4 + ev.daysShown, 'left', 'top', true);
    const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';
    const filename = `actuals-${holidex}-${ev.department?.label}-${ev.scheduleDay}.xlsx`.replace(/ /g, '-');
    await this.xls.outputSheet(filename);
  }

  async exportActualsTemplate(ev) {
    this.xls.startSheet();
    this.xls.addRow(['Department', 'Outlet Index', 'Employee Code', 'Date In', 'Time In', 'Date Out', 'Time Out'], 'EEEEEE');
    this.xls.addRow(['', '', '', '', '', '', '']);
    this.xls.addRow(['', '', '', '', '', '', '']);
    const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';

    await this.xls.outputSheet(`actualstemplate-${holidex}-${ev.department?.label}-${ev.scheduleDay}.xlsx`);
  }

  async uploader(event) {
    this.log.debug('uploader lastEv', this.lastEv);
    this.ui.info('Processing import file...');
    await this.xls.openFile(event.target.files[0]);
    this.uploadControl.nativeElement.value = ''; //allows us to do this again

    let rows = [];
    let badRows = 0;
    for (let i = 2; i <= this.xls.numRows(); i += 1)
      try {
        if (!this.xls.getCellVal(1, i)) break; //end of file
        let startD = dayjs.utc(this.xls.getCellVal(4, i).toString());
        let startT = dayjs.utc(this.xls.getCellVal(5, i).toString());
        let start = this.context.contextDateToISO(startD.add(startT.hour(), 'hour').add(startT.minute(), 'minute'));

        let endD = dayjs.utc(this.xls.getCellVal(6, i).toString());
        let endT = dayjs.utc(this.xls.getCellVal(7, i).toString());
        let end = this.context.contextDateToISO(endD.add(endT.hour(), 'hour').add(endT.minute(), 'minute'));

        rows.push({
          eId: this.xls.getCellVal(3, i),
          start,
          end,
          person: this.lastEv.allPersons.find((p) => p.personnelNo == this.xls.getCellVal(3, i)),
        });
      } catch (e) {
        badRows += 1;
      }
    this.log.debug('import rows', rows);
    //check out each person
    let badPeople = rows
      .filter((r) => !r.person)
      .map((r) => r.eId)
      .join(', ');
    if (badPeople) {
      this.ui.acknowledgeError('Cannot import this file, the following employee codes are not matched: ' + badPeople);
      return;
    }
    //check out each date
    let sd = dayjs.utc(this.lastEv.startDate);
    let ed = sd.add(this.lastEv.daysShown + 1, 'day');
    let early = rows.filter((r) => dayjs.utc(r.start).isBefore(sd));
    let late = rows.filter((r) => dayjs.utc(r.start).isAfter(ed));
    if (early.length) {
      this.ui.acknowledgeError('Cannot import this file, ' + early.length + ' shift(s) occur before the start date.');
      return;
    }
    if (late.length) {
      this.ui.acknowledgeError('Cannot import this file, ' + late.length + ' shift(s) occur after the end date.');
      return;
    }

    //Okay now checkout each shift to see if we have
    let badCount = 0;
    let offLimit = 60 * 60 * 1000; // possible offset in milliseconds (1 hour)
    rows.forEach((r) => {
      let shifts = r.person.shifts;
      //try to match the shift with 2 hours wiggle room on start
      let tshift = shifts.find((s) => {
        //check is this department shift
        if (s.otherDep) return false;
        //check is it inside 2 hours
        let diff = dayjs(r.start).diff(s.start);
        if (Math.abs(diff) > offLimit) return false;
        return true;
      });
      if (!tshift) badCount += 1;
      r.matchedShift = tshift;
    });
    if (badCount || badRows) {
      let gc = rows.length - badCount;
      let prompt = 'Update remaining ' + gc + ' shift(s)?';
      if (badCount) prompt = badCount + ' shift(s) could not be matched to scheduled shifts. ' + prompt;
      if (badRows) prompt = badRows + ' row(s) were badly formatted. ' + prompt;

      this.ui.confirmAction(
        prompt,
        () => this.importShifts(rows),
        () => 0,
        'Update Shifts',
        'Cancel'
      );
    } else {
      this.ui.confirmAction(
        'All shifts matched to scheduled shifts. Update shift(s)?',
        () => this.importShifts(rows),
        () => 0
      );
    }
  }

  importShifts(rows: any) {
    let ups = rows
      .filter((r) => r.matchedShift)
      .map((row) => {
        let ms = row.matchedShift;
        let capturedShift = {
          id: ms.capturedShifts?.length ? ms.capturedShifts[0].id : 0, //overwrite if we have one
          start: row.start,
          end: row.end,
          breaks: ms.breaks,
          quantity: ms.quantity,
          absence: false,
          absenceComment: null as string,
          absenceType: null,
          status: 'DRAFT',
        };
        return this.scheduleData.updateCapturedShift(ms.id, capturedShift);
      });
    this.uiService.info('Processing Shift Import');
    //now zip them and execute on them
    zip(...ups)
      .pipe(first())
      .subscribe((result) => {
        this.log.debug('importShifts result', result);
        this.lastEv.reload();
      });
  }
}
