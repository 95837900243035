<ng-container *ngIf="shift">
  <p-dialog [header]="'Shift ' + shift.departmentLabel" [visible]="shift" showEffect="fade" modal="true" [closable]="false">
    <div class="content">
      <ng-container *ngIf="shift.detail.type != 'AGENCY'">
        <div>
          <label>Employees: {{ shift.personLabel }}</label>
        </div>
        <h4 *ngIf="cshift.importComment">
          {{ cshift.importComment }}
        </h4>
        <br />
        <div class="p-field">
          <label
            >Type
            <p-dropdown
              [options]="typeCodes"
              [(ngModel)]="shift.detail.type"
              optionLabel="label"
              optionValue="code"
              placeholder="select type"
              appendTo="body"
              [disabled]="!isUnplanned || disabled"
            >
            </p-dropdown>
          </label>
        </div>
        <div class="p-field" *ngIf="isUnplanned && shift.detail.type === 'ABSENCE'">
          <label
            >Absence
            <p-dropdown
              [options]="shiftAbsenceCodes"
              [(ngModel)]="shift.detail.absenceType"
              optionLabel="label"
              optionValue="code"
              placeholder="select absence"
              appendTo="body"
              [disabled]="disabled"
            >
            </p-dropdown>
          </label>
        </div>
        <div *ngIf="isUnplanned && shift.detail.type == 'ABSENCE'">
          <br />
          <label>Absent Comment</label>
          <textarea [rows]="5" maxlength="1024" pInputTextarea [(ngModel)]="cshift.absenceComment" [disabled]="disabled"></textarea>
        </div>
      </ng-container>
      <ng-container *ngIf="shift.detail.type == 'AGENCY'">
        <div>
          <label>Agency Shift</label>
        </div>
        <br />
      </ng-container>
      <br />
      <div class="p-d-inline-flex p-jc-around doublewidth">
        <p-panel header="Scheduled Hours" *ngIf="!isUnplanned">
          <div class="spacer" *ngIf="canBeAbsent()">&nbsp;</div>
          <div class="p-field" *ngIf="shift.detail.type == 'AGENCY'">
            <label
              >Number of Agency Members
              <p-inputNumber
                min="1"
                max="1000"
                size="4"
                maxlength="4"
                mode="decimal"
                [(ngModel)]="shift.detail.quantity"
                [disabled]="true"
              ></p-inputNumber>
            </label>
          </div>
          <div>
            <app-time-range
              [(startDateString)]="shift.detail.start"
              [(endDateString)]="shift.detail.end"
              [disabled]="true"
            ></app-time-range>
          </div>
          <br />
          <div class="p-field" *ngIf="hasBreaks()">
            <label
              >Breaks
              <p-inputNumber
                min="0"
                max="120"
                size="3"
                maxlength="3"
                mode="decimal"
                [(ngModel)]="shift.detail.breaks"
                [disabled]="true"
              ></p-inputNumber>
            </label>
            <label> &nbsp;mins </label>
          </div>
        </p-panel>
        <p-panel header="Actual Hours">
          <div class="spacer" *ngIf="!isUnplanned && canBeAbsent()">
            <p-checkbox [(ngModel)]="cshift.absence" [binary]="true" label="Report Absence" [disabled]="disabled"></p-checkbox>
            <label *ngIf="cshift.absence">
              <p-dropdown
                [options]="absenceCodes"
                [(ngModel)]="cshift.absenceType"
                optionLabel="label"
                optionValue="code"
                placeholder="select type"
                appendTo="body"
                [disabled]="disabled"
              >
              </p-dropdown>
              <p-message styleClass="warning-message" *ngIf="!isValidAbsence()" severity="error" text="missing absence type"></p-message>
            </label>
          </div>
          <div *ngIf="cshift.absence">
            <br />
            <label>Absent Comment</label>
            <textarea [rows]="5" maxlength="1024" pInputTextarea [(ngModel)]="cshift.absenceComment" [disabled]="disabled"></textarea>
          </div>
          <ng-container>
            <div class="p-field" *ngIf="shift.detail.type == 'AGENCY'">
              <label
                >Number of Agency Members
                <p-inputNumber
                  min="1"
                  max="1000"
                  size="4"
                  maxlength="4"
                  mode="decimal"
                  [(ngModel)]="cshift.quantity"
                  [disabled]="disabled"
                ></p-inputNumber>
                <p-message styleClass="warning-message" *ngIf="!cshift.quantity" severity="error" text="should be non-zero"></p-message>
              </label>
            </div>
            <div>
              <app-time-range [(startDateString)]="cshift.start" [(endDateString)]="cshift.end" [disabled]="disabled"></app-time-range>
              <p-message styleClass="warning-message" *ngIf="!isValidTimeRange()" severity="error" text="invalid worked hours"></p-message>
            </div>
            <br />

            <div class="p-field" *ngIf="hasBreaks()">
              <label
                >Breaks
                <p-inputNumber
                  min="0"
                  max="120"
                  size="3"
                  maxlength="3"
                  mode="decimal"
                  [(ngModel)]="cshift.breaks"
                  [disabled]="disabled"
                ></p-inputNumber>
              </label>
              <label> &nbsp;mins </label>
            </div>
          </ng-container>
        </p-panel>
        <p-panel header="Check Ins" *ngIf="useDeviceCheckIn">
          <div class="checkholder">
            <div class="checkitem p-d-flex" *ngFor="let checkIn of checkIns">
              <div>
                <i class="pi pi-sign-in" *ngIf="checkIn.type == 'IN'"></i> <i class="pi pi-sign-out" *ngIf="checkIn.type == 'OUT'"></i>
              </div>

              <div>
                &nbsp; &nbsp;{{ checkIn.type == 'IN' ? 'Check In' : 'Check Out ' }} at <b>{{ checkIn.time }}</b>
                <span *ngIf="checkIn.length"><br />&nbsp; &nbsp;({{ checkIn.length }})</span>
                <span *ngIf="!checkIn.length && checkIn.type == 'IN'" class="bold"><br />&nbsp; &nbsp;(No check out)</span>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
    <p-footer>
      <button
        type="button"
        pButton
        icon="pi pi-minus"
        (click)="cancelShiftEdit()"
        label="Cancel Shift"
        class="p-button-danger"
        [disabled]="disabled"
      ></button>
      <button type="button" pButton icon="pi pi-times" (click)="cancelEdit()" label="Exit" class="p-button-secondary"></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="saveEdit()"
        [disabled]="!isValidTimeRange() || !isValidAbsence() || !isValidAgency() || disabled"
        label="Save"
      ></button>
    </p-footer>
  </p-dialog>
</ng-container>
