import { Injectable } from '@angular/core';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { gql } from 'apollo-angular';

import { LinkService } from './link.service';
import { CentralDataService } from './central-data.service';
import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';
import { SentryService } from './sentry.service';

import { makeISODateOnly } from 'src/commontypes/drivers';
import { FullDepartment } from 'src/commontypes/manning';
import { DepartmentName, createDeptExistsFn, createSubDeptGetFn } from 'src/commontypes/util';

@Injectable({
  providedIn: 'root',
})
export class RegionDataService {
  constructor(
    private linkService: LinkService,
    private centralData: CentralDataService,
    private authService: AuthService,
    private log: LoggingService,
    private sentryService: SentryService
  ) {}

  getHotels() {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
              query getHotels {
                allHotels(schedulingActive: true) {
                  ...${fragments.hotelBasic.name}
                }
              }
              ${fragments.hotelBasic.definition}
            `,
          })
          .pipe(map(({ data }: any) => data.allHotels))
      )
    );
  }

  getHotel(hotelId: string) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
              query getHotel($hotelId: ID) {
                allHotels(id:$hotelId) {
                  ...${fragments.hotelDetails.name}
                }
              }
              ${fragments.hotelDetails.definition}
            `,
            variables: {
              hotelId,
            },
          })
          .pipe(map(({ data }: any) => data.allHotels[0]))
      )
    );
  }

  getCluster(hotelId: string) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query<{ getHotelCluster: { id; name; hotelIds: string[] } }>({
            query: gql`
              query getCluster($hotelId: ID!) {
                getHotelCluster(hotelId: $hotelId) {
                  id
                  name
                  hotelIds
                }
              }
            `,
            variables: {
              hotelId,
            },
          })
          .pipe(map(({ data }) => data.getHotelCluster))
      )
    );
  }

  getClusterHotels(hotelId: string) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query<{ getHotelCluster: { id; name; hotels: any[] } }>({
            query: gql`
              query getClusterHotels($hotelId: ID!) {
                getHotelCluster(hotelId: $hotelId) {
                  id
                  name
                  hotels {
                    ...${fragments.hotelDetails.name}
                  }
                  hotelIds
                }
              }
              ${fragments.hotelDetails.definition}
            `,
            variables: {
              hotelId,
            },
          })
          .pipe(map(({ data }) => data.getHotelCluster?.hotels || []))
      )
    );
  }

  getClusterHotelsWithDeptConfig(hotelId: string) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query<{ getHotelCluster: { id; name; hotels: any[] } }>({
            query: gql`
              query getClusterHotelsConfig($hotelId: ID!) {
                getHotelCluster(hotelId: $hotelId) {
                  id
                  name
                  hotels {
                    ...${fragments.deptoutsourceconfighotel.name}
                    ...${fragments.deptconfighotel.name}
                    ...${fragments.hotelDetails.name}
                  }
                  hotelIds
                }
              }
              ${fragments.hotelDetails.definition}
              ${fragments.deptconfighotel.definition}
              ${fragments.deptoutsourceconfighotel.definition}
            `,
            variables: {
              hotelId,
            },
          })
          .pipe(map(({ data }) => data.getHotelCluster?.hotels || []))
      )
    );
  }

  getHotelWithDeptConfig(hotelId: string) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
              query getHotelWithDeptConfig($hotelId: ID) {
                allHotels(id:$hotelId) {
                  ...${fragments.deptoutsourceconfighotel.name}
                  ...${fragments.deptconfighotel.name}
                  ...${fragments.hotelDetails.name}
                }
              }
              ${fragments.hotelDetails.definition}
              ${fragments.deptconfighotel.definition}
              ${fragments.deptoutsourceconfighotel.definition}
            `,
            variables: {
              hotelId,
            },
          })
          .pipe(map(({ data }: any) => data.allHotels[0]))
      )
    );
  }

  getRoles(includeDisabled = false) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query<{ id; name; label; jobBand; disabled }>({
            query: gql`
              query getRoles($skipDisabled: Boolean) {
                allRoles(skipDisabled: $skipDisabled) {
                  id
                  name
                  label
                  jobBand
                  disabled
                }
              }
            `,
            variables: { skipDisabled: !includeDisabled },
          })
          .pipe(map(({ data }: any) => data.allRoles))
      )
    );
  }

  getFullDepartmentsForCluster(hotelIdIn?: string, checkUserAccess = false) {
    //gets a list of all functionaldepartments including outlets and associated services
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId: string) => {
        return forkJoin({
          baseDepartments: this.centralData.getDepartments(),
          hotels: this.getClusterHotelsWithDeptConfig(hotelId).pipe(first()),
        }).pipe(
          mergeMap((res) => {
            const obs = res.hotels.map(({ holidexCode }) => {
              return forkJoin({
                bars: this.authService.getOutlets('BAR').pipe(first()),
                rests: this.authService.getOutlets('RESTAURANT').pipe(first()),
                retails: this.authService.getOutlets('RETAIL').pipe(first()),
              }).pipe(map((r) => ({ ...r, holidexCode })));
            });
            return forkJoin(obs).pipe(
              map((outletAuth) => {
                return {
                  outletAuth: outletAuth.reduce(
                    (
                      prev: {
                        [holidex: string]: {
                          rests: { all?: boolean; main?: number[]; kitchens?: number[]; stewarding?: number[] };
                          bars: { all?: boolean; main?: number[]; kitchens?: number[]; stewarding?: number[] };
                          retails: { all?: boolean; main?: number[]; kitchens?: number[]; stewarding?: number[] };
                        };
                      },
                      { holidexCode, rests, bars, retails }
                    ) => {
                      prev[holidexCode] = {
                        rests,
                        bars,
                        retails,
                      };
                      return prev;
                    },
                    {}
                  ),
                  ...res,
                };
              })
            );
          }),
          map(({ outletAuth, hotels, baseDepartments }) => {
            const fullDepts: Array<FullDepartment & { holidexCode: string }> = [];
            const kitchen = baseDepartments.find((dept) => dept.name === 'KITCHEN') || { defaultOrder: 999 };
            const stewarding = baseDepartments.find((dept) => dept.name === 'STEWARDING') || { defaultOrder: 999 };
            hotels.forEach(({ id: hotelId, holidexCode, restaurants, bars, retailOutlets, ...hotel }) => {
              const hotelOutletAuth = outletAuth[holidexCode];
              const departmentExists = createDeptExistsFn(hotel.departmentConfig);
              const getSubDeptConfig = createSubDeptGetFn(hotel.departmentConfig);
              baseDepartments
                .filter((dept) => departmentExists(dept.name))
                .forEach((d: any) => {
                  if (d.name == 'RESTAURANTS') {
                    // add one department for each outlet
                    restaurants
                      .filter((o) => hotelOutletAuth.rests.all || hotelOutletAuth.rests.main?.includes(o.index))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: d.name,
                          class: d.class,
                          label: o.name + ' (outlet)',
                          outletIndex: o.index,
                          outletType: 'RESTAURANT',
                          defaultOrder: d.defaultOrder,
                        });
                      });
                    // add one department for each outlet that needs it
                    restaurants
                      .filter((o) => o.kitchen && (hotelOutletAuth.rests.all || hotelOutletAuth.rests.kitchens?.includes(o.index)))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: 'KITCHEN',
                          class: d.class,
                          label: o.name + ' - Kitchen (outlet)',
                          outletIndex: o.index,
                          outletType: 'RESTAURANT',
                          defaultOrder: kitchen.defaultOrder,
                        });
                      });
                    // add one department for each outlet that needs it
                    restaurants
                      .filter((o) => o.stewarding && (hotelOutletAuth.rests.all || hotelOutletAuth.rests.main.includes(o.index)))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: 'STEWARDING',
                          class: d.class,
                          label: o.name + ' - Stewarding (outlet)',
                          outletIndex: o.index,
                          outletType: 'RESTAURANT',
                          defaultOrder: stewarding.defaultOrder,
                        });
                      });
                  } else if (d.name == 'BARS') {
                    // add one department for each outlet
                    bars
                      .filter((o) => hotelOutletAuth.bars.all || hotelOutletAuth.bars.main.includes(o.index))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: d.name,
                          class: d.class,
                          label: o.name + ' (outlet)',
                          outletIndex: o.index,
                          outletType: 'BAR',
                          defaultOrder: d.defaultOrder,
                        });
                      });
                    bars
                      .filter((o) => o.kitchen && (hotelOutletAuth.bars.all || hotelOutletAuth.bars.kitchens?.includes(o.index)))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: 'KITCHEN',
                          class: d.class,
                          label: o.name + ' - Kitchen (outlet)',
                          outletIndex: o.index,
                          outletType: 'BAR',
                          defaultOrder: kitchen.defaultOrder,
                        });
                      });
                    bars
                      .filter((o) => o.stewarding && (hotelOutletAuth.bars.all || hotelOutletAuth.bars.stewarding?.includes(o.index)))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: 'STEWARDING',
                          class: d.class,
                          label: o.name + ' - Stewarding (outlet)',
                          outletIndex: o.index,
                          outletType: 'BAR',
                          defaultOrder: stewarding.defaultOrder,
                        });
                      });
                  } else if (d.name == 'RETAIL_OUTLETS') {
                    // add one department for each outlet
                    retailOutlets
                      .filter((o) => hotelOutletAuth.retails.all || hotelOutletAuth.retails.main?.includes(o.index))
                      .forEach((o) => {
                        fullDepts.push({
                          hotelId,
                          holidexCode,
                          name: d.name,
                          class: d.class,
                          label: o.name + ' (retail outlet)',
                          outletIndex: o.index,
                          outletType: 'RETAIL',
                          defaultOrder: d.defaultOrder,
                        });
                      });
                  } else if (d.name === 'KITCHEN') {
                    fullDepts.push({ ...d, hotelId, holidexCode, label: 'Main Kitchen' });
                  } else if (d.name === 'STEWARDING') {
                    fullDepts.push({ ...d, hotelId, holidexCode, label: 'Main Stewarding' });
                  } else {
                    fullDepts.push({ ...d, holidexCode, hotelId });
                    const subDepts = getSubDeptConfig(d.name);
                    if (subDepts.kitchen) {
                      fullDepts.push({
                        ...d,
                        holidexCode,
                        hotelId,
                        label: d.label + ' - Kitchen',
                        subDeptName: 'KITCHEN',
                        defaultOrder: kitchen.defaultOrder,
                      });
                    }
                    if (subDepts.stewarding) {
                      fullDepts.push({
                        ...d,
                        holidexCode,
                        hotelId,
                        label: d.label + ' - Stewarding',
                        subDeptName: 'STEWARDING',
                        defaultOrder: kitchen.defaultOrder,
                      });
                    }
                  } // just add it as a normal department
                });
            });
            return fullDepts;
          }),
          mergeMap((fullDepts) => {
            if (!checkUserAccess) {
              return of(fullDepts).pipe(
                map((res) => res.filter((d) => !!d)),
                first()
              );
            }
            // outlets are handled by the outletFilter call above so these are excluded form the filtering
            const obs = fullDepts.map(({ holidexCode, ...dept }) =>
              dept.outletType
                ? of(dept)
                : this.authService
                    .hasDepartment({ name: DepartmentName[dept.name], subDeptName: dept.subDeptName && DepartmentName[dept.subDeptName] })
                    .pipe(
                      map((hasAccess) => (hasAccess ? dept : undefined)),
                      first()
                    )
            );
            return forkJoin(obs).pipe(
              map((res) => res.filter((d) => !!d)),
              first()
            );
          })
        );
      })
    );
  }

  getFullDepartmentsForHotel(hotelIdin: string = null, checkUserAccess = false) {
    //gets a list of all functionaldepartments including outlets and associated services
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        this.log.info(`going with hotel ${hotelId}`);
        return forkJoin({
          baseDepartments: this.centralData.getDepartments(),
          hotel: this.getHotelWithDeptConfig(hotelId).pipe(first()),
          outletFilter: checkUserAccess
            ? forkJoin({
                bars: this.authService.getOutlets('BAR').pipe(first()),
                rests: this.authService.getOutlets('RESTAURANT').pipe(first()),
                retails: this.authService.getOutlets('RETAIL').pipe(first()),
              }).pipe(
                map(({ bars, rests, retails }) => {
                  return ({ name, outletIndex, outletType }: { name; outletIndex; outletType: 'BAR' | 'RESTAURANT' | 'RETAIL' }) => {
                    const res =
                      outletType === 'RETAIL'
                        ? retails.all || retails.main.includes(outletIndex)
                        : outletType === 'BAR'
                        ? bars.all ||
                          (name === 'KITCHEN'
                            ? bars.kitchens.includes(outletIndex)
                            : name === 'STEWARDING'
                            ? bars.stewarding.includes(outletIndex)
                            : bars.main.includes(outletIndex))
                        : rests.all ||
                          (name === 'KITCHEN'
                            ? rests.kitchens.includes(outletIndex)
                            : name === 'STEWARDING'
                            ? rests.stewarding.includes(outletIndex)
                            : rests.main.includes(outletIndex));
                    return res;
                  };
                })
              )
            : of((data: { name; outletIndex; outletType: 'BAR' | 'RESTAURANT' | 'RETAIL' }) => true),
        }).pipe(
          map(({ baseDepartments, hotel, outletFilter }) => {
            const fullDepts: FullDepartment[] = [];
            if (!hotel) {
              this.linkService.setCurrentRegion;
              this.sentryService.sendMessage(
                'Unable to retrieve hotel for department config',
                'warning',
                {
                  hotelId,
                  hotelIdin,
                  currentRegion: this.linkService.getCurrentRegion(),
                },
                'warning-full-departments-get'
              );
              return fullDepts;
            }
            const { restaurants, bars, retailOutlets, departmentConfig } = hotel;
            const kitchen = baseDepartments.find((dept) => dept.name === 'KITCHEN') || { defaultOrder: 999 };
            const stewarding = baseDepartments.find((dept) => dept.name === 'STEWARDING') || { defaultOrder: 999 };
            const departmentExists = createDeptExistsFn(departmentConfig);
            const getSubDeptConfig = createSubDeptGetFn(departmentConfig);
            baseDepartments
              .filter((dept) => departmentExists(dept.name))
              .forEach((d: any) => {
                if (d.name == 'RESTAURANTS') {
                  // add one department for each outlet
                  restaurants
                    .filter((o) => outletFilter({ outletIndex: o.index, outletType: 'RESTAURANT', name: d.name }))
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: d.name,
                        class: d.class,
                        label: o.name + ' (outlet)',
                        outletIndex: o.index,
                        outletType: 'RESTAURANT',
                        defaultOrder: d.defaultOrder,
                      });
                    });
                  // add one department for each outlet that needs it
                  restaurants
                    .filter(
                      (o) =>
                        o.kitchen && !o.kitchenServedBy && outletFilter({ name: 'KITCHEN', outletIndex: o.index, outletType: 'RESTAURANT' })
                    )
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: 'KITCHEN',
                        class: d.class,
                        label: o.name + ' - Kitchen (outlet)',
                        outletIndex: o.index,
                        outletType: 'RESTAURANT',
                        defaultOrder: kitchen.defaultOrder,
                      });
                    });
                  // add one department for each outlet that needs it
                  restaurants
                    .filter(
                      (o) =>
                        o.stewarding &&
                        !o.stewardingServedBy &&
                        outletFilter({ name: 'STEWARDING', outletIndex: o.index, outletType: 'RESTAURANT' })
                    )
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: 'STEWARDING',
                        class: d.class,
                        label: o.name + ' - Stewarding (outlet)',
                        outletIndex: o.index,
                        outletType: 'RESTAURANT',
                        defaultOrder: stewarding.defaultOrder,
                      });
                    });
                } else if (d.name == 'BARS') {
                  // add one department for each outlet
                  bars
                    .filter((o) => outletFilter({ outletIndex: o.index, outletType: 'BAR', name: d.name }))
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: d.name,
                        class: d.class,
                        label: o.name + ' (outlet)',
                        outletIndex: o.index,
                        outletType: 'BAR',
                        defaultOrder: d.defaultOrder,
                      });
                    });
                  bars
                    .filter(
                      (o) => o.kitchen && !o.kitchenServedBy && outletFilter({ name: 'KITCHEN', outletIndex: o.index, outletType: 'BAR' })
                    )
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: 'KITCHEN',
                        class: d.class,
                        label: o.name + ' - Kitchen (outlet)',
                        outletIndex: o.index,
                        outletType: 'BAR',
                        defaultOrder: kitchen.defaultOrder,
                      });
                    });
                  bars
                    .filter(
                      (o) =>
                        o.stewarding &&
                        !o.stewardingServedBy &&
                        outletFilter({ name: 'STEWARDING', outletIndex: o.index, outletType: 'BAR' })
                    )
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: 'STEWARDING',
                        class: d.class,
                        label: o.name + ' - Stewarding (outlet)',
                        outletIndex: o.index,
                        outletType: 'BAR',
                        defaultOrder: stewarding.defaultOrder,
                      });
                    });
                } else if (d.name == 'RETAIL_OUTLETS') {
                  // add one department for each outlet
                  retailOutlets
                    .filter((o) => outletFilter({ outletIndex: o.index, outletType: 'RETAIL', name: d.name }))
                    .forEach((o) => {
                      fullDepts.push({
                        hotelId,
                        name: d.name,
                        class: d.class,
                        label: o.name + ' (retail outlet)',
                        outletIndex: o.index,
                        outletType: 'RETAIL',
                        defaultOrder: d.defaultOrder,
                      });
                    });
                } else if (d.name === 'KITCHEN') {
                  fullDepts.push({ ...d, hotelId, label: 'Main Kitchen' });
                } else if (d.name === 'STEWARDING') {
                  fullDepts.push({ ...d, hotelId, label: 'Main Stewarding' });
                } else {
                  fullDepts.push({ ...d, hotelId });
                  const subDepts = getSubDeptConfig(d.name);
                  if (subDepts.kitchen) {
                    fullDepts.push({
                      ...d,
                      hotelId,
                      label: d.label + ' - Kitchen',
                      subDeptName: 'KITCHEN',
                      defaultOrder: kitchen.defaultOrder,
                    });
                  }
                  if (subDepts.stewarding) {
                    fullDepts.push({
                      ...d,
                      hotelId,
                      label: d.label + ' - Stewarding',
                      subDeptName: 'STEWARDING',
                      defaultOrder: kitchen.defaultOrder,
                    });
                  }
                } // just add it as a normal department
              });
            return fullDepts;
          }),
          mergeMap((fullDepts) => {
            if (!checkUserAccess) {
              return of(fullDepts).pipe(
                map((res) => res.filter((d) => !!d)),
                first()
              );
            }
            const obs = fullDepts.map((dept) =>
              // outlets are handled by the outletFilter call above so these are excluded form the filtering
              dept.outletType
                ? of(dept)
                : this.authService
                    .hasDepartment({ name: DepartmentName[dept.name], subDeptName: dept.subDeptName && DepartmentName[dept.subDeptName] })
                    .pipe(
                      map((hasAccess) => (hasAccess ? dept : undefined)),
                      first()
                    )
            );
            return forkJoin(obs).pipe(
              map((res) => res.filter((d) => !!d)),
              first()
            );
          })
        );
      })
    );
  }

  getComments(dateRef: Date, hotelIdin: string = null) {
    return this.linkService.GQLRegion().pipe(
      switchMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
      switchMap(({ link, hotelId }) =>
        link
          .query({
            query: gql`
              query getComments($hotelId: ID!, $date: CalendarDate!, $type: CommentType!) {
                getComments(hotelId: $hotelId, date: $date, type: $type) {
                  id
                  createdAt
                  updatedAt
                  title
                  text
                  createdBy
                }
              }
            `,
            variables: {
              hotelId,
              date: makeISODateOnly(dateRef),
              type: 'STATUS_DASHBOARD',
            },
            fetchPolicy: 'network-only',
          })
          .pipe(
            first(),
            map(({ data }: any) => data.getComments)
          )
      )
    );
  }

  saveComment(dateRef: Date, comment: any, hotelIdin: string = null) {
    return this.linkService.GQLRegion().pipe(
      switchMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
      switchMap(({ link, hotelId }) =>
        comment.id ? this.updateComment(link, comment) : this.createComment(link, hotelId, dateRef, comment)
      )
    );
  }

  createComment(link, hotelId, dateRef, comment) {
    return link
      .mutate({
        mutation: gql`
          mutation createComment($data: CreateCommentInput!, $date: CalendarDate!, $hotelId: ID!) {
            createComment(data: $data, hotelId: $hotelId, date: $date) {
              id
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          hotelId,
          date: makeISODateOnly(dateRef),
          data: {
            type: 'STATUS_DASHBOARD',
            text: comment.text,
          },
        },
      })
      .pipe(map(({ data }: any) => data.createComment));
  }

  updateComment(link, comment) {
    return link
      .mutate({
        mutation: gql`
          mutation updateComment($data: CommentInput!, $commentId: ID!) {
            updateComment(data: $data, commentId: $commentId) {
              id
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          commentId: comment.id,
          data: {
            text: comment.text,
          },
        },
      })
      .pipe(map(({ data }: any) => data.updateComment));
  }
}

const fragments = {
  hotelBasic: {
    name: 'HotelBasicFragment',
    definition: gql`
      fragment HotelBasicFragment on Hotel {
        id
        schedulingActive
        schedulingOptimalAvailable
        brand {
          id
          name
          abbreviation
        }
        cluster {
          id
          name
          hotels {
            id
            holidexCode
            name
          }
        }
        timezoneOffset
        timezone
        baseContractHoursPerWeek
        firstWeekday
        numberOfRooms
        workDaysPerWeek
        firstWeekday
        holidayEntitlementPerYear
        holidayHoursEntitlementPerYear
        holidayConfig {
          type
        }
        holidexCode
        localCurrency
        name
        status
        publicHolidaysPerYear
        peopleSoftBusinessUnit
        peopleSoftDeptId
      }
    `,
  },
  hotelDetails: {
    name: 'HotelDetailsFragment',
    definition: gql`
      fragment HotelDetailsFragment on Hotel {
        id
        schedulingActive
        schedulingOptimalAvailable
        cluster {
          id
          name
          hotels {
            id
            holidexCode
            name
          }
        }
        brand {
          id
          name
          abbreviation
        }
        timezoneOffset
        timezone
        baseContractHoursPerWeek
        numberOfRooms
        workDaysPerWeek
        holidayEntitlementPerYear
        holidayHoursEntitlementPerYear
        holidayConfig {
          type
        }
        holidexCode
        localCurrency
        firstWeekday
        name
        status
        publicHolidaysPerYear
        peopleSoftBusinessUnit
        peopleSoftDeptId
        restaurants {
          id
          name
          index
          efpm
          kitchen
          stewarding
          breakfast
          lunch
          dinner
          late
          openingDays {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
          kitchenServedBy {
            departmentName
            outletIndex
            outletType
          }
          stewardingServedBy {
            departmentName
            outletIndex
            outletType
          }
          january
          february
          march
          april
          may
          june
          july
          august
          september
          october
          november
          december
          fullYear
        }
        bars {
          id
          name
          index
          efpm
          kitchen
          stewarding
          am
          pm
          breakfast
          lunch
          dinner
          late
          openingDays {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
          kitchenServedBy {
            departmentName
            outletIndex
            outletType
          }
          stewardingServedBy {
            departmentName
            outletIndex
            outletType
          }
          january
          february
          march
          april
          may
          june
          july
          august
          september
          october
          november
          december
          fullYear
        }
        retailOutlets {
          id
          name
          index
        }
      }
    `,
  },
  deptconfighotel: {
    name: 'DeptConfigHotelFragment',
    definition: gql`
      fragment DeptConfigHotelFragment on Hotel {
        departmentConfig {
          departmentName
          enabled
          outsourced
          outsourceRatio
          subDeptConfig {
            kitchen
            kitchenServedBy {
              departmentName
              outletIndex
              outletType
            }
            stewarding
            stewardingServedBy {
              departmentName
              outletIndex
              outletType
            }
          }
          servedBy {
            departmentName
            outletIndex
            outletType
          }
        }
      }
    `,
  },
  deptoutsourceconfighotel: {
    name: 'DeptOutsourceConfigHotelFragment',
    definition: gql`
      fragment DeptOutsourceConfigHotelFragment on Hotel {
        outsourcedBellPerson
        outsourcedConcierge
        outsourcedExecHousekeeper
        outsourcedHousePorter
        outsourcedHskSupervisor
        outsourcedPublicAreas
        outsourcedRoomAttendant
      }
    `,
  },
};
