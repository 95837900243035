import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { CentralDataService } from 'src/services/central-data.service';
import { ContextService } from 'src/services/context.service';
import { LoggingService } from 'src/services/logging.service';
import { SentryService } from 'src/services/sentry.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  schedulerName = environment.names.scheduler;
  $authRoute = new BehaviorSubject<boolean>(false);
  sub$: Subscription;
  displaySidebar = false;
  available = true;
  closedMessage: string;
  closed = false;
  env = '';
  ssoId: string;
  savingSSO = false;
  confirmSSOId: string;
  captureSSO = false;

  constructor(
    public contextService: ContextService,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private log: LoggingService,
    private centralData: CentralDataService,
    private uiService: UIService,
    private authService: AuthService,
    private messageService: MessageService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    if (!environment.production) {
      this.env = ` [${environment.sentryEnv === 'develop' ? 'dev' : 'stg'}]`;
    }
    this.sub$ = new Subscription();
    this.sub$.add(
      this.router.events.subscribe((event) => {
        this.displaySidebar = false;
        if (event instanceof NavigationStart) {
          this.$authRoute.next(!UserService.isUnauthenticatedUrl(event.url));
        }
      })
    );
    this.userService.monitorExpiries();
    this.userService.monitorClaimsDeprecated();
    this.$authRoute
      .pipe(
        filter((auth) => auth),
        first()
      )
      .subscribe(() => {
        this.log.debug('auth route update');
        this.contextService.start();
        if (!environment.ignoreUnavailable) {
          this.sub$.add(
            this.centralData
              .watchGetHealth()
              .pipe(filter((health) => this.available !== (health.available && !health.schedulerUnavailable)))
              .subscribe((health) => {
                this.available = health.available && !health.schedulerUnavailable;
                if (this.available) {
                  this.closedMessage = undefined;
                  this.closed = false;
                  this.uiService.acknowledgeAction(
                    `${environment.names.scheduler} connection has been re-established.`,
                    undefined,
                    `${environment.names.scheduler} available`
                  );
                } else if (health.schedulerUnavailable) {
                  this.uiService.acknowledgeError(
                    health.schedulerMessage || `${environment.names.scheduler} is currently unavailable, please wait.`,
                    undefined,
                    `${environment.names.scheduler} unavailable`
                  );
                  this.closedMessage = health.schedulerMessage;
                  this.closed = true;
                } else if (health.error) {
                  this.closedMessage = 'Unable to connect to the Team planner, please check your internet connection.';
                  this.closed = false;
                } else if (!health.available) {
                  this.uiService.acknowledgeError('Team planner is currently unavailable, please check your internet connection.');
                  this.closed = true;
                }
              })
          );
        }
      });
    if (environment.checkSSOID) {
      this.log.debug('Checking for SSO ID');
      this.authService
        .currentUser()
        .pipe(
          filter((data) => !!data?.email),
          first()
        )
        .subscribe(() => {
          this.authService
            .getSSOId()
            .pipe(first())
            .subscribe((ssoId) => {
              this.log.debug('SSO ID retrieved', { ssoId });
              this.captureSSO = !ssoId;
            });
        });
    }
  }

  ngOnDestroy() {
    if (this.sub$) {
      this.sub$.unsubscribe();
      this.sub$ = undefined;
    }
  }

  getRouteTitle() {
    if (this.route.snapshot.firstChild) {
      return this.route.snapshot.firstChild.data.title;
    }
    return '';
  }

  saveSSO() {
    const ssoId = this.ssoId;
    this.savingSSO = true;
    this.userService
      .updateSSOID(ssoId)
      .pipe(first())
      .subscribe(
        (res) => {
          this.savingSSO = false;
          this.captureSSO = false;
          this.messageService.add({
            key: 'bottom-right',
            severity: 'success',
            summary: `Successfully updated Merlin ID to ${ssoId}`,
            sticky: true,
          });
          // TBD verify SSO by authenticating
        },
        (err) => {
          this.savingSSO = false;
          this.captureSSO = false;
          if (err.message?.includes('already has an ssoID')) {
            this.uiService.acknowledgeError(
              'Your account already has a Merlin ID. Please log in again to update.',
              () => undefined,
              'Merlin ID exists'
            );
          } else if (err.message?.includes('already assigned')) {
            this.uiService.acknowledgeError(
              'There is another account using your Merlin ID. Please contact an administrator.',
              () => undefined,
              'Merlin ID invalid'
            );
          } else {
            this.sentryService.showAndSendError(err, 'Unable to update Merlin ID', undefined, { ssoId }, 'set-sso-error');
          }
        }
      );
  }
}
