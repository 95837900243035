import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as dayjs from 'dayjs';
import { absenceTypeCodes, shiftTypeCodes } from 'src/commontypes/shifts';
import { ContextService } from 'src/services/context.service';
import { CentralDataService } from 'src/services/central-data.service';
import { UIService } from 'src/services/ui.service';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/services/logging.service';

@Component({
  selector: 'app-captured-shift',
  styleUrls: ['./captured-shift.component.scss'],
  templateUrl: './captured-shift.component.html',
})
export class CapturedShiftComponent implements OnInit {
  @Input() shift: any;
  @Input() disabled = false;
  @Output() onChange = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onShiftCancel = new EventEmitter();

  cshift = null;
  isUnplanned = false;
  checkIns = [];
  useDeviceCheckIn = environment.useDeviceCheckIn;

  typeCodes = shiftTypeCodes.filter((s) => s.canAssign);
  absenceCodes = absenceTypeCodes;
  shiftAbsenceCodes = absenceTypeCodes.filter((s) => !s.timeSheetOnly);

  constructor(
    private uiService: UIService,
    private context: ContextService,
    private central: CentralDataService,
    private log: LoggingService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.shift) return;
    this.isUnplanned = !this.shift.detail.id;
    this.cshift = this.shift.detail.capturedShifts[0];
    if (!this.cshift) {
      if (!this.central.isProdOnlyDept(this.shift.departmentName)) {
        this.typeCodes = shiftTypeCodes.filter((s) => s.canAssign);
      } else {
        this.typeCodes = shiftTypeCodes.filter((s) => s.canAssign && !s.prodOnly);
      }

      //no cshift
      this.cshift = {
        id: 0,
        start: this.shift.detail.start,
        end: this.shift.detail.end,
        breaks: this.shift.detail.breaks,
        quantity: this.shift.detail.quantity,
        absence: false,
        absenceComment: null as string,
        absenceType: 'NO_SHOW',
        status: 'DRAFT',
      };
      this.shift.detail.capturedShifts = [this.cshift];
    }
    this.checkIns =
      this.shift.row.checkIns
        ?.filter((v) => {
          if (v.time.diff(this.cshift.start, 'minutes') < -30) return false;
          if (v.time.diff(this.cshift.end, 'minutes') > 30) return false;
          return true;
        })
        .map((v) => {
          const hours = v.length && Math.floor(v.length * 24);
          const minutes = v.length && Math.round((v.length * 24 - hours) * 60);

          return {
            time: this.context.isoToContextDate(v.time).format('HH:mm:ss'),
            type: v.checkInType,
            length: v.length
              ? `${hours} hours` + (minutes >= 1 ? ` ${String(minutes > 59 ? 59 : minutes).padStart(2, '0')} mins` : '') + ' elapsed'
              : undefined,
          };
        }) || [];
  }

  saveEdit() {
    if (this.isUnplanned) {
      this.shift.detail.start = this.cshift.start;
      this.shift.detail.end = this.cshift.end;
    }
    this.cshift.status = 'DRAFT';
    this.cshift.cancel = false;
    this.onChange.emit(this.shift);
  }

  cancelShiftEdit() {
    this.uiService.confirmActionDanger('Cancel this shift?', 'Cancel Shift', () => {
      this.cshift.absence = false;
      this.cshift.absenceType = null;
      this.cshift.absenceComment = null;
      this.cshift.cancel = true;
      this.onChange.emit(this.shift);
    });
  }

  isValidAgency() {
    if (this.shift.detail.type === 'AGENCY') {
      if (this.shift.row?.id) {
        return true;
      }
      return !!this.cshift.quantity;
    } else {
      return true;
    }
  }

  isValidAbsence() {
    return this.cshift.absence ? !!this.cshift.absenceType : true;
  }

  isValidTimeRange() {
    return dayjs(this.cshift.start).isBefore(this.cshift.end);
  }

  hasBreaks() {
    const typeCode = shiftTypeCodes.find(({ code }) => code === this.shift?.detail?.type);
    return typeCode?.hasBreaks;
  }

  canBeAbsent() {
    const shiftType = shiftTypeCodes.find((entry) => entry.code === this.shift.detail?.type);
    return shiftType?.canBeAbsent;
  }

  cancelEdit() {
    this.onCancel.emit();
  }
}
