import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScheduleDataService } from 'src/services/schedule-data.service';

import { UIService } from 'src/services/ui.service';
import { mapMonthEnumToNumber, monthList, Right, Scope } from 'src/commontypes/util';
import { LoggingService } from 'src/services/logging.service';
import { XLSService } from 'src/services/xls.service';
import { RegionDataService } from 'src/services/region-data.service';
import dayjs, { Dayjs } from 'dayjs';
import { ContextService } from 'src/services/context.service';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-reportexports',
  styleUrls: ['./reportexports.component.scss'],
  templateUrl: './reportexports.component.html',
})
export class ReportExportsComponent implements OnInit, OnDestroy {
  isWorking = false;
  public dateStart: Date = new Date();
  public dateEnd: Date = new Date();
  public numWeeks = 99;
  public totalDays = 0;
  public hasEmployeeAccess = false;
  public hasDeptAccess = false;

  public departments = [];

  numWeekList = [
    { code: 99, label: 'Calendar Month ' },
    { code: 1, label: '1 Week ' },
    { code: 2, label: '2 Weeks ' },
    { code: 3, label: '3 Weeks ' },
    { code: 4, label: '4 Weeks ' },
    { code: 5, label: '5 Weeks ' },
    { code: 6, label: '6 Weeks ' },
    { code: 7, label: '7 Weeks ' },
    { code: 8, label: '8 Weeks ' },
  ];

  constructor(
    private scheduleData: ScheduleDataService,
    private regionData: RegionDataService,
    private log: LoggingService,
    private xls: XLSService,
    private authService: AuthService,
    private context: ContextService
  ) {}

  ngOnInit() {
    this.dateStart = dayjs(this.dateEnd).subtract(30, 'days').toDate();
    this.regionData.getFullDepartmentsForHotel().subscribe((data) => (this.departments = data));
    this.authService
      .hasAnyRoles([
        [Scope.REGION_ADMIN, Right.READ],
        [Scope.SCHEDULE_ADMIN, Right.READ],
        [Scope.SCHEDULE_MANNING, Right.READ],
      ])
      .pipe(first())
      .subscribe((hasRole) => {
        this.hasEmployeeAccess = hasRole;
      });
    this.authService
      .hasAnyRoles([
        [Scope.REGION_ADMIN, Right.READ],
        [Scope.SCHEDULE_ADMIN, Right.READ],
        [Scope.SCHEDULE_MANNING, Right.READ],
        [Scope.SCHEDULE, Right.READ],
        [Scope.SCHEDULE_CAPTURE, Right.READ],
      ])
      .pipe(first())
      .subscribe((hasRole) => {
        this.hasDeptAccess = hasRole;
      });
  }

  ngOnDestroy(): void {}

  setTimespan() {
    if (this.numWeeks < 20) {
      //set number of weeks
      const start = this.context.currentHotelWeekStart(this.dateStart);
      this.dateStart = start.toDate();
      this.totalDays = this.numWeeks * 7;
      this.dateEnd = start.add(this.totalDays - 1, 'day').toDate();
    } else {
      //calendar month
      this.dateStart = dayjs(this.dateStart).startOf('month').toDate();
      this.dateEnd = dayjs(this.dateStart).endOf('month').toDate();
      this.totalDays = dayjs(this.dateEnd).diff(this.dateStart, 'days') + 1;
    }
  }

  exportEmployeeTotal() {
    if (this.isWorking) return;
    this.isWorking = true;
    this.context.getCurrentBasicHotel;

    this.setTimespan();

    this.scheduleData
      .getPersonsShiftSummary(this.dateStart, this.dateEnd)
      .pipe(first())
      .subscribe(async (data) => {
        this.xls.startSheet();
        this.xls.addRow([
          'Employee',
          'T&A ID',
          'Contracted Hours',
          'Work Days',
          'From',
          'To',
          'Hotel',
          'Department',
          'Working Hours',
          'Annual Leave',
          'Public Holiday',
          'Leave Without Absence',
          'Unpaid Leave',
          'Parental Leave',
          'Lieu',
          'Sick Leave',
          'Other',
        ]);

        data.forEach((emp) => {
          this.outputEmployeeTotal(emp);
        });
        const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';
        await this.xls.outputSheet(
          `employeedata-${holidex}-${this.dateStart.getFullYear()}-${this.dateStart.getMonth() + 1}-${this.dateStart.getDate()}.xlsx`
        );
        this.isWorking = false;
      });
  }

  private outputEmployeeTotal(emp) {
    if (!emp || !emp.person) {
      return;
    }
    //got to establish a list of unique hotel/departments for this employee
    var depList = [];
    emp.shiftItems.forEach((i) =>
      i.items.forEach((s) => {
        if (
          !depList.find(
            (d) =>
              d.holidexCode == s.holidexCode &&
              d.departmentName == s.departmentName &&
              (d.outletType ? d.outletType === s.outletType && d.outletIndex === s.outletIndex : !s.outletType) &&
              (d.subDeptName ? d.subDeptName === s.subDeptName : !s.subDeptName)
          )
        )
          depList.push({
            holidexCode: s.holidexCode,
            departmentName: s.departmentName,
            subDeptName: s.subDeptName,
            outletType: s.outletType,
            outletIndex: s.outletIndex,
          });
      })
    );

    //so output a row for each department
    const rows = depList.map((loc) => {
      return [
        emp.person.firstName + ' ' + emp.person.lastName,
        emp.person.personnelNo,
        ((emp.person.currentContract?.contractedHours || 0) * this.totalDays) / 7,
        ((emp.person.currentContract?.workDays || 0) * this.totalDays) / 7,
        dayjs(this.dateStart).format('YYYY-MM-DD'),
        dayjs(this.dateEnd).format('YYYY-MM-DD'),
        loc.holidexCode,
        loc.departmentName,
        this.sumAllShifts(emp.capturedItems, 'WORK', null, false, loc), //Actual Working Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'ANNUAL_LEAVE', false, loc), //Actual AL Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'PUBLIC_HOLIDAY', false, loc), //Actual AL Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'NO_SHOW', false, loc), //Actual NS Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'UNPAID_LEAVE', false, loc) +
          this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'LEAVE_WITHOUT_PAY', false, loc), //Actual UL Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'PARENTAL_LEAVE', false, loc), //Actual MP Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'TIME_IN_LIEU', false, loc), //Actual LT Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'SICK_LEAVE', false, loc), //Actual SL Hours
        this.sumAllShifts(emp.capturedItems, 'ABSENCE', 'OTHER', false, loc), //Actual Other Hours
      ];
    });
    if (rows.length) rows.forEach((r) => this.xls.addRow(r));
  }

  exportEmployee() {
    if (this.isWorking) return;
    this.isWorking = true;

    this.setTimespan();

    const dates = new Array(this.totalDays).fill(0).map((d, i) => dayjs(this.dateStart).add(i, 'day').format('YYYY-MM-DD'));

    this.scheduleData
      .getPersonsShiftSummary(this.dateStart, this.dateEnd)
      .pipe(first())
      .subscribe(async (data) => {
        this.xls.startSheet();
        this.xls.addRow([
          'Employee',
          'T&A ID',
          '0 Hour',
          'Contracted Hours',
          'WorkDays',
          'Home Dep',
          'Annual Leave',
          'Public Holiday',
          'Day',
          'Scheduled Working Hours',
          'Scheduled Working Breaks',
          'Scheduled AL Hours',
          'Scheduled UL Hours',
          'Scheduled LWP Hours',
          'Scheduled SL Hours',
          'Scheduled MP Hours',
          'Scheduled LT Hours',
          'Scheduled PH Hours',
          'Scheduled O Hours',
          'Actual Working Hours',
          'Actual Working Breaks',
          'Actual AL Hours',
          'Actual UL Hours',
          'Actual LWP Hours',
          'Actual SL Hours',
          'Actual MP Hours',
          'Actual LT Hours',
          'Actual PH Hours',
          'Actual O Hours',
          'Actual NS Hours',
          'Actual Worked Shifts',
        ]);

        data.forEach((emp) => {
          dates.forEach((d) => this.outputEmployeeDay(emp, d));
        });
        const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';
        await this.xls.outputSheet(
          `employeedata-daily-${holidex}-${this.dateStart.getFullYear()}-${this.dateStart.getMonth() + 1}-${this.dateStart.getDate()}.xlsx`
        );
        this.isWorking = false;
      });
  }

  private outputEmployeeDay(emp, day) {
    if (!emp || !emp.person) {
      return;
    }

    const row = [
      emp.person.firstName + ' ' + emp.person.lastName,
      emp.person.personnelNo,
      emp.person.currentContract?.casual ? 'YES' : 'NO',
      emp.person.currentContract?.contractedHours || 0,
      emp.person.currentContract?.workDays || 0,
      emp.person.departmentLabel,
      emp.person.holidayDays,
      emp.person.publicHolidayDays,
      day,
      this.sumShifts(emp.shiftItems, day, 'WORK', null), //Scheduled work hours
      this.sumShifts(emp.shiftItems, day, 'WORK', null, true), //Scheduled work break hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'ANNUAL_LEAVE'), //Scheduled AL Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'UNPAID_LEAVE'), //Scheduled UL Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'LEAVE_WITHOUT_PAY'), //Scheduled LWP Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'SICK_LEAVE'), //Scheduled SL Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'PARENTAL_LEAVE'), //Scheduled MP Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'TIME_IN_LIEU'), //Scheduled LT Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'PUBLIC_HOLIDAY'), //Scheduled LT Hours
      this.sumShifts(emp.shiftItems, day, 'ABSENCE', 'OTHER'), //Scheduled Other Hours
      this.sumShifts(emp.capturedItems, day, 'WORK', null), //Actual Working Hours
      this.sumShifts(emp.capturedItems, day, 'WORK', null, true), //Actual Working break Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'ANNUAL_LEAVE'), //Actual AL Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'UNPAID_LEAVE'), //Actual UL Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'LEAVE_WITHOUT_PAY'), //Actual LWP Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'SICK_LEAVE'), //Actual SL Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'PARENTAL_LEAVE'), //Actual MP Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'TIME_IN_LIEU'), //Actual LT Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'PUBLIC_HOLIDAY'), //Actual LT Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'OTHER'), //Actual Other Hours
      this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'NO_SHOW'), //Actual NS Hours
      this.joinShifts(emp.capturedItems, day, 'WORK', null), //List of Work shifts
    ];

    this.xls.addRow(row);
  }

  exportDepartment() {
    if (this.isWorking) return;
    //establish every day on the month in array dates
    this.isWorking = true;

    this.setTimespan();
    const dates = new Array(this.totalDays).fill(0).map((d, i) => dayjs(this.dateStart).add(i, 'day').format('YYYY-MM-DD'));

    this.scheduleData
      .getDepartmentShiftSummary(this.dateStart, this.dateEnd)
      .pipe(first())
      .subscribe(async (data) => {
        this.xls.startSheet();
        this.xls.addRow([
          'Department',
          'Dep Name',
          'O Type',
          'O Index',
          'Day',
          'Scheduled Working Hours',
          'Scheduled Working Breaks',
          'Scheduled Agency Hours',
          'Scheduled Optimal Hours',
          'Scheduled AL Hours',
          'Scheduled UL Hours',
          'Scheduled LWP Hours',
          'Scheduled SL Hours',
          'Scheduled MP Hours',
          'Scheduled LT Hours',
          'Scheduled O Hours',
          'Actual Working Hours',
          'Actual Working Breaks',
          'Actual Agency Hours',
          'Actual Optimal Hours',
          'Actual AL Hours',
          'Actual UL Hours',
          'Actual LWP Hours',
          'Actual SL Hours',
          'Actual MP Hours',
          'Actual LT Hours',
          'Actual O Hours',
          'Actual NS Hours',
        ]);

        data.forEach((dep) => this.outputDepartment(dep, dates));
        const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';
        await this.xls.outputSheet(
          `departmentdata-${holidex}-${this.dateStart.getFullYear()}-${this.dateStart.getMonth() + 1}-${this.dateStart.getDate()}.xlsx`
        );
        this.isWorking = false;
      });
  }

  private outputDepartment(dep, datelist) {
    const fullDep = this.departments.find(
      (dept) =>
        dept.name === dep.departmentName &&
        (dept.outletType ? dep.outletType === dept.outletType && dep.outletIndex === dept.outletIndex : !dep.outletType) &&
        (dept.subDeptName ? dep.subDeptName === dept.subDeptName : !dep.subDeptName)
    );
    if (!fullDep || !dep) {
      return;
    }
    //go through every date
    datelist.forEach((d) => this.outputDepartmentDay(fullDep, dep, d));
  }

  private outputDepartmentDay(fullDep, dep, day) {
    const row = [
      fullDep.label,
      fullDep.name,
      fullDep.outletType,
      fullDep.outletIndex ? fullDep.outletIndex : '',
      day,
      this.sumShifts(dep.shiftItems, day, 'WORK', null), //Scheduled work hours
      this.sumShifts(dep.shiftItems, day, 'WORK', null, true), //Scheduled work breaks
      this.sumShifts(dep.shiftItems, day, 'AGENCY', null), //Scheduled agency work hours
      this.getBefItem(dep.befForecastItems, day),
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'ANNUAL_LEAVE'), //Scheduled AL Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'UNPAID_LEAVE'), //Scheduled UL Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'LEAVE_WITHOUT_PAY'), //Scheduled LWP Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'SICK_LEAVE'), //Scheduled SL Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'PARENTAL_LEAVE'), //Scheduled MP Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'TIME_IN_LIEU'), //Scheduled LT Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'OTHER'), //Scheduled Other Hours
      this.sumShifts(dep.capturedItems, day, 'WORK', null), //Actual Working Hours
      this.sumShifts(dep.capturedItems, day, 'WORK', null, true), //Actual Working breaks
      this.sumShifts(dep.capturedItems, day, 'AGENCY', null), //Actual Agency Hours
      this.getBefItem(dep.befActualItems, day), //Actual Optimal Hours
      this.sumShifts(dep.capturedItems, day, 'ABSENCE', 'ANNUAL_LEAVE'), //Actual AL Hours
      this.sumShifts(dep.capturedItems, day, 'ABSENCE', 'UNPAID_LEAVE'), //Actual UL Hours
      this.sumShifts(dep.capturedItems, day, 'ABSENCE', 'LEAVE_WITHOUT_PAY'), //Actual LWP Hours
      this.sumShifts(dep.capturedItems, day, 'ABSENCE', 'SICK_LEAVE'), //Actual SL Hours
      this.sumShifts(dep.capturedItems, day, 'ABSENCE', 'PARENTAL_LEAVE'), //Actual MP Hours
      this.sumShifts(dep.capturedItems, day, 'ABSENCE', 'TIME_IN_LIEU'), //Actual LT Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'OTHER'), //Actual Other Hours
      this.sumShifts(dep.shiftItems, day, 'ABSENCE', 'NO_SHOW'), //Actual NS Hours
    ];
    this.xls.addRow(row);
  }

  private sumAllShifts(shifts: any[], type, aType, getBreaks, singleLoc) {
    return shifts.reduce((a, s) => a + this.sumShifts(shifts, s.date, type, aType, getBreaks, singleLoc), 0);
  }

  private sumShifts(shifts: any[], day, inType, aType, getBreaks = false, singleLoc = null) {
    const type = Array.isArray(inType) ? inType : [inType];
    const shiftDay = shifts?.find((s) => s.date == day);
    if (!shiftDay) return 0; //no such day
    let items = shiftDay.items;
    if (singleLoc)
      items = items.filter(
        (s) =>
          s.holidexCode == singleLoc.holidexCode &&
          s.departmentName == singleLoc.departmentName &&
          (s.outletType ? s.outletType == singleLoc.outletType && s.outletIndex == singleLoc.outletIndex : !s.outletType) &&
          (s.subDeptName ? s.subDeptName === singleLoc.subDeptName : !singleLoc.subDeptName)
      );
    return items
      .filter((s) => type.includes(s.type) && (!aType || s.absenceType == aType))
      .reduce((t, s) => t + (getBreaks ? s.breakHours : s.hours), 0);
  }

  private makeShiftString(times: any[]): String[] {
    return times.map(
      (v) => this.context.isoToContextDate(v.start).format('HH:mm') + ' - ' + this.context.isoToContextDate(v.end).format('HH:mm')
    );
  }

  private joinShifts(shifts: any[], day, type, aType, getBreaks = false, singleLoc = null) {
    const shiftDay = shifts?.find((s) => s.date == day);
    if (!shiftDay) return ''; //no such day
    let items = shiftDay.items;
    if (singleLoc)
      items = items.filter(
        (s) =>
          s.holidexCode == singleLoc.holidexCode &&
          s.departmentName == singleLoc.departmentName &&
          (s.outletType ? s.outletType == singleLoc.outletType && s.outletIndex == singleLoc.outletIndex : !s.outletType) &&
          (s.subDeptName ? s.subDeptName === singleLoc.subDeptName : !singleLoc.subDeptName)
      );
    const list = items
      .filter((s) => s.type == type && (!aType || s.absenceType == aType))
      .reduce((t, s) => [...t, ...this.makeShiftString(s.times)], []);
    return list.join(';');
  }

  private getBefItem(items: [any], day) {
    const item = items.find((s) => s.date == day);
    return item ? item.hours : 0;
  }

  exportEmployeeRoster() {
    if (this.isWorking) return;
    this.isWorking = true;

    this.setTimespan();
    const dates = new Array(this.totalDays).fill(0).map((d, i) => dayjs(this.dateStart).add(i, 'day').format('YYYY-MM-DD'));

    this.scheduleData
      .getPersonsShiftSummary(this.dateStart, this.dateEnd)
      .pipe(first())
      .subscribe(async (data) => {
        this.xls.startSheet();
        this.xls.addRow([
          'Department',
          'T&A ID',
          'Employee Name',
          'Date',
          'In Time',
          'Out Time',
          'Break Time',
          'Working Hours',
          'Total Employee Hours',
          'Code',
        ]);

        data.forEach((emp) => {
          dates.forEach((d) => this.outputEmployeeDayRoster(emp, d));
        });
        const holidex = this.context.getCurrentBasicHotel()?.holidexCode || '';
        await this.xls.outputSheet(
          `employeerosterdata-${holidex}-${this.dateStart.getFullYear()}-${this.dateStart.getMonth() + 1}-${this.dateStart.getDate()}.xlsx`
        );
        this.isWorking = false;
      });
  }

  private outputEmployeeDayRoster(emp, day) {
    if (!emp || !emp.person) {
      return;
    }

    if (emp.person.contractsEndDate && dayjs(emp.person.contractsEndDate).isBefore(day)) return;

    const shiftDay = emp.capturedItems?.find((s) => s.date == day);

    if (!shiftDay?.items?.length) {
      //no shifts
      this.xls.addRow([
        emp.person.departmentLabel,
        emp.person.personnelNo,
        emp.person.firstName + ' ' + emp.person.lastName,
        day,
        '',
        '',
        0,
        0,
        0,
        'NONE',
      ]);
    } else {
      const workTypes = ['WORK', 'TRAINING', 'APPRENTICE', 'ADMIN'];
      shiftDay.items.forEach((s) => {
        let start = '';
        let end = '';
        let AT = '';
        if (workTypes.includes(s.type)) {
          AT = 'WORK';
          start = this.context.isoToContextDate(s.times[0].start).format('HH:mm');
          end = this.context.isoToContextDate(s.times[0].end).format('HH:mm');
        } else {
          if (s.type == 'OFF') AT = 'OFF';
          else AT = s.absenceType;
        }
        this.xls.addRow([
          emp.person.departmentLabel,
          emp.person.personnelNo,
          emp.person.firstName + ' ' + emp.person.lastName,
          day,
          start,
          end,
          this.sumShifts(emp.capturedItems, day, workTypes, null, true) * 60,
          this.sumShifts(emp.capturedItems, day, workTypes, null),
          //Sum these shift types into one column
          this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'ANNUAL_LEAVE') +
            this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'SICK_LEAVE') +
            this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'PARENTAL_LEAVE') +
            this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'TIME_IN_LIEU') +
            this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'PUBLIC_HOLIDAY') +
            this.sumShifts(emp.capturedItems, day, 'ABSENCE', 'OTHER'), //end of column
          AT,
        ]);
      });
    }
  }
}
